import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { deleteTodo, editTodo, postTodo } from "./actions/todo.action";
import { deleteComment, postComment } from "./actions/comment.action";
import { deleteLink, postLink } from "./actions/link.action";
import { isEmpty } from "./assets/Utils";
import Modal from "./assets/Modal";
import { getModal01 } from "./actions/modal01.action";
import Ficheprojet from "./assets/Ficheprojet";
import { getID } from "./actions/id.action";
import { getFiche } from "./actions/fiche.action";

// ---------------------------------------------------------------------- A changer
import { editMissionprio } from "./actions/missionprio.action";
import { setMissionstatus } from "./actions/missionstatus.action";

//-------------------------------------------------------------------------WYSIWYG
// import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Main() {
  //-------------------------------------------------------------------------WYSIWYG
  // const [editorState, setEditorState] = useState('');
  // const handleEditorChange = (newEditorState) => {setEditorState(newEditorState);};
  const convertDraftToHtml = (rawContentState) => {
    const contentState = convertFromRaw(rawContentState);
    const editorState = EditorState.createWithContent(contentState);
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };


  const [lastprojectmodalbis, setLastprojectmodalbis] = useState(false);
  const [showallprices, setShowallprices] = useState(false);


  const zones = useSelector((state) => state.zoneReducer.zone);

  const projects = useSelector((state) => state.projectReducer.projects);
  const missions = useSelector((state) => state.missionReducer);
  const comments = useSelector((state) => state.commentReducer.comments);

  const [dept, setDept] = useState(null);
  const [selectedstatus, setSelectedstatus] = useState("inprogress");
  const [parprojet, setParprojet] = useState(true);
  const [selectedprojectid, setSelectedprojectid] = useState(null);
  const [selectedmissionid, setSelectedmissionid] = useState(null);
  const dispatch = useDispatch();
  const [mytitle, setMytitle] = useState(
    "Cliquez sur la carte pour afficher les projets par département"
  );
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const [mysearch, setMysearch] = useState(null);
  const lookatme = (data) => {
    setMysearch(data);
    console.log(data);
  };

  const lightmapfill = "#333399";
  const mapfill = "#FFCC00";
  const orange = "#FFA500";
  const red = "#FF0000";
  const mapoff = "#dddddd";
  const aurexus = "#000066";
  const maphover_fill = "#7ABBEF";
  const mapstroke = "#EEEEEE";
  const mapstroke_width = 0.8;
  const mapWidth = 750;
  const mapHeight = 750;
  const grey = "#cfcfc4";

  const [isHovered, setIsHovered] = useState(false);
  const [colorvary, setColorvary] = useState(mapfill);

  const zonestyle = (id) => {
    const project =
      !isEmpty(projects) &&
      projects.find((project) => project.departement === id);

    let fill;

    if (!isEmpty(project) && project.departement === id) {
      if (project.priorite <= 2) {
        fill = lightmapfill;
      } else if (project.priorite <= 4) {
        fill = mapfill;
      } else if (project.priorite <= 7) {
        fill = orange;
      } else if (project.priorite <= 10) {
        fill = red;
      } else {
        fill = aurexus;
      }
    } else {
      fill = grey;
    }

    return {
      fill,
    };
  };

  const deptinfo = (zone, title) => {
    setMytitle(title);
    setDept(zone);
  };
  const reinitialize = () => {
    setMytitle(
      "Cliquez sur la carte pour afficher les projets par département"
    );
    setDept(null);
  };
  const showmodal = () => {
    dispatch(getModal01(true));
  };

  // const showproject = (id) => {
  //   alert(id);
  // };

  const selectedproject = (id) => {
    dispatch(getID(id));
    dispatch(getFiche(true));
    setLastprojectmodalbis(false);
  };
  const selectedmission = (id) => {
    // dispatch(getID(id));
    setMissionid(id);
    setFichemission(true);
    setLastprojectmodalbis(false);
    // dispatch(getFiche(true));
  };

  const [myfilter, setMyfilter] = useState("projet");
  const filtermethod = (selectedValue) => {
    setMyfilter(selectedValue);
  };

  /* ----------------------------------------------------
                         Price DataBase Section
    ----------------------------------------------------- */
  const prices = useSelector((state) => state.unitpriceReducer.unitprices);
  const [showpricelist, setShowpricelist] = useState(false);
  const listdisplaytoggle = () => {
    setShowpricelist(!showpricelist);
    setShowallprices(true);
  };

  /* ----------------------------------------------------
                         JOIN REDUCERS
    ----------------------------------------------------- */
  const prestations = useSelector(
    (state) => state.typeprestationsReducer.typeprestations
  );
  const items = useSelector((state) => state.itemReducer.items);
  const descriptions = useSelector(
    (state) => state.descriptionReducer.descriptions
  );
  const unities = useSelector((state) => state.unityReducer.unities);

  /* ----------------------------------------------------
                         Toogle Map Display
    ----------------------------------------------------- */
  const [whichmap, setWhichmap] = useState(true);
  const togglemapdisplay = () => {
    setWhichmap(!whichmap);
  };

  const mapstyle = {
    opacity: 1,
    color: "#000000",
    fill: "#fff3e3",
    fillOpacity: 1,
    fillRule: "evenodd",
    stroke: "#5d5d5d",
    strokeWidth: 0.5,
    strokeLinecap: "butt",
    strokeLinejoin: "miter",
    marker: "none",
    markerStart: "none",
    markerMid: "none",
    markerEnd: "none",
    strokeMiterlimit: 4,
    strokeDasharray: "none",
    strokeDashoffset: 0,
    strokeOpacity: 0.51459853,
    visibility: "visible",
    display: "inline",
    overflow: "visible",
  };

  const newmap = [
    {
      id: "Z1",
      title: "Ain",
      coul: "#FF0000",
      url: "http://google.fr",
      target: "_self",
      path: "M237.125,152.725l-1.7-1l-2.4,3.3l-2.7,1.6l-2,0.1l-0.2-1.4l-1.6-0.8l-2,2.2l-1.5,0.1v-1.5h-1.5l-2.1-3.9 l-2.5-1.6l-2.7,0.6l-2.9-0.8l-2.9,10.5l-0.8,4l1.5,4.6l1.5-0.3l1.8,2.9l3.2-0.3l3,1l1.5-2.5l1.4-0.4l5.6,7.6l2.9-3.3l1.1-6.8 l-0.4-4.7h1.5l1.3-1.4h-0.1l0.3-2.6l2.8-1.7L237.125,152.725z",
    },
    {
      id: "Z2",
      title: "Aisne",
      coul: "#6699CC",
      url: "",
      target: "_self",
      path: "M179.025,42.325l-6.3,0.4l-0.2,1.8l-1.9,4.1l1.1,3.5l0.2,5.1l-0.3,2.2l1.1,0.9l-1.3,0.6l-1.2,2.8l-1.3,0.8 l1.4,2.3l-1.5-0.1l0.4,1.5l1.2-0.8l1.4,0.6l0.3,1.4l-1.1,0.8l1.3,0.4l0.9,1.2l-0.3,1.4l1.9,2.1l4.7,3l3.8-5.1l-1.3-0.6l0.5-1.4 l-0.8-1.2l2.7-1.1l-1.6-4l0.6-1.4l4-2l2.7,1l0.4-1.5l-0.1-7.1l1.4-0.1l2.5-3.6l-0.7-1.6l0.7-1.7l-0.4-2.9h-0.2l-1.8-0.6v-0.1 l-7.8-2.1l-2.6,0.9l-1.2-0.9L179.025,42.325z",
    },
    {
      id: "Z3",
      title: "Allier",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M166.425,143.625l-4.2,1.5l-1.1,0.9l0.8,2.7l-0.8,1.2l-4.4,0.5l-1.8,2.9l0.1,0.1l0.5,1.9 c1.3,0.8,2.5,1.6,3.7,2.5l1.8,4.1l1.3,0.6l5.6-4l1.1,0.9l-0.6,1.4l2.2,2.2l1.6-0.2l0.9,1.2l6.5,0.3l0.7,1.6l3.2,0.2l1.8,2.2 l2.7-2.5l-0.7-6.2l0.2-1.5l2-0.6l1.8-2.3l0.3-2.8l-0.9-1.1l-3.9-1.5l-3.3-6.8l-3,3l-2.2-1.8l-1.4,0.8l-3.2-0.1l-2.9-3.1h-2 L166.425,143.625z",
    },
    {
      id: "Z4",
      title: "Alpes-de-Haute-Provence",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M257.225,204.825l-3,1.7l-4.5,5.1l-5.6-1.7l-1.8,2.1l-0.4,1.9l-1.7-2.3l-1.7,0.4l-2.6,2l-0.7,4.3l-2-0.5 l1.7,2.4l-1.9-0.5l-2.9,0.7l-0.5,0.1l-1.2,0.1l-2.1,2.4l1,2.7l-0.6,1.5l1.9,2.6l-0.9,1.3l1.8,0.5l3,3l2.6,0.1l1.4-0.9l2,2.4 l5.1-4.3l2.9,1.8l0.6-1.7l4.9-0.2l1.5-0.7l-0.6-1.3l2.6-1.9l2.6,0.3l-3.5-3.9l-2.1-4.2l1-2.7l3-3.4l-0.1-0.1l1-2.5l-2-2.7l1.1-0.9 L257.225,204.825z",
    },
    {
      id: "Z5",
      title: "Hautes-Alpes",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M240.825,193.025l0.9,2.1l2.2,1.5l-0.2,2.9l-1.6-0.5l-6.3,1.9l-0.4,1.4l-2.8,0.6l-0.4,1.6l-0.2,1.6 l-2.9,0.2l-1.2,3.4l1.5,1.1l-1.3,1.3l-2.1-0.7l-1.6,2.5l0.8,1.3l5,2.9l-0.1,2.3l2.9-0.7l1.9,0.5l-1.7-2.4l2,0.5l0.7-4.3l2.6-2 l1.7-0.4l1.7,2.3l0.4-1.9l1.8-2.1l5.6,1.7l4.5-5.1l3-1.7l0,0l0.1-0.1l0.9-1.1l1.8,0.1l-1.9-4.7l-3-0.2l-2.6-1.6l-0.4-3.3l-1.4-0.1 l-1.2-2.6l-3,0.6l-1,1l-3.9-1.7L240.825,193.025z",
    },
    {
      id: "Z6",
      title: "Alpes-Maritimes",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M273.325,218.625l-6,2.1l-4.6-2.4l-3.2-0.7l-3-3.6l-3,3.4l-1,2.7l2.1,4.2l3.5,3.9l-2.6-0.3l-2.6,1.9 l0.6,1.3l-1.5,0.7l2.7,1.4l0.9,2.9l2.8,1.2l-0.4,1.5l1,2l1.3-2.1l2.8-1.4l0.6-2l7.2-4l0.2-3.6l3.1-4.3l1.1-2.2L273.325,218.625z",
    },
    {
      id: "Z7",
      title: "Ardèche",
      coul: "#33CCFF",
      url: "#",
      target: "_self",
      path: "M200.325,194.825l-1.3,1.2l0.3,1.4l-1.7,0.2l-1.7,2.9l-2.9,1l-3.4,3.2l1,4.5l2.2,4.2l1.3,2.9l3.9,2.5    l3.5-2.2l0.1,1.6l1.3-1.1h1.3l3.1,1.7l-0.1-1.8l1-7.2l1.5-3l-0.4-3.5l1.7-2.3l0.7-3.1l-1.8-5.6l-0.3-5.2l-1-2.1l-2.5,1.4l-1.6,2.4    l-1.7,0.4l-0.5,2.1v1.5l-1.3-0.6L200.325,194.825z",
    },
    {
      id: "Z8",
      title: "Ardennes",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M205.325,42.725v-2.1l1.3-2.6l-1.4-0.7l-2,2.1l-0.9,3.3l-4.3,1.9l-2.7-0.9l-1.4,0.3l0.4,2.9l-0.7,1.7 l0.7,1.6l-2.5,3.6l-1.4,0.1l0.1,7.1l3.4,0.5l4.9,3.3l10.1,1v-0.1l2-1.8l-0.6-1.2l1.6-2.6l-0.5-4.4l1-1l2.8,1.1l1.1-1.4l0.8-0.6 l-1.5,0.2v-1.6l-3-0.8l-3.4-2.9h-3l0.5-3.2L205.325,42.725z",
    },
    {
      id: "Z9",
      title: "Ariège",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M148.025,257.525l-1.1-2.6l-4.3-1.9l-1.2-2.8l-0.9,1.2l-2.8-1.1l-0.4,1.4l-1.4,0.2l-1.6-2.7l-1.5,0.5    l1.5,2.4l-2.6,0.8l0.1,2.8l-3.1-2l-1.3,0.5l-2,2v3l-1.4,0.5l-1.9,2.6l0.5,1.4l4.9,1.3l2.4,2.2l3.2-0.2l2.8,3.8l0.6-1.5l1.7-0.1    l5.2,2.6l0,0l4.5-1.3l0.6-1.3l3.7-0.1l-2.4-2.8l-1.6,0.6l-1.8-0.8l-0.4-2.8l1.7-0.3l0.6-1.5l-1.3-1.3l1.1-1.1L148.025,257.525z",
    },
    {
      id: "Z10",
      title: "Aube",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M178.225,92.625l1.9,0.7l2.2,2.8l1.2,4.1l1-1.1l1.8,2.1l2.5,5.4l6.3-0.8l1.5,0.7l1.1-1l3.1-0.4l2.3-2    l1.9,0.3l-0.1-2.4l2.8-1.3l0.1-1.3l-0.3-5l-2.8-1.5l-1.2-2.6l0.4-1.7l-1.7-0.5l-1.2,0.7c-1-0.3-1.9-0.7-2.9-1.1l-1.3-1l-0.1-3.1    l-1.6-0.2l-3.3,0.4l-4.7,3.8v1.7l-4.5-0.6l-1.6-2.3l-1.5,2.5l-1.5,0.4L178.225,92.625z",
    },
    {
      id: "Z11",
      title: "Aude",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M146.425,246.625l-0.7-1.4l-4.3,5l1.2,2.8l4.3,1.9l1.1,2.6l0.1,1.6l-1.1,1.1l1.3,1.3l-0.6,1.5l-1.7,0.3    l0.4,2.8l1.8,0.8l1.6-0.6l2.4,2.8l4.3-2l-0.7-3l1.3-0.6l6.8,0.5l4.2-2.8l3.9,2.4l0.3-4.9l2.8-5.4l1.3-1.3l0,0l-0.8-1l-7-2.4    l-0.5-1.4l-1.9,2.6l-1.5,0.4l-0.7-1.4l-1.3,0.8l-1.7-2.5l0.3-1.4l-6-0.7l-1.5,0.2l-0.5,1.7l-3.2-0.5l-0.9-1.1l-0.2,0.4    L146.425,246.625z",
    },
    {
      id: "Z12",
      title: "Aveyron",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M153.325,208.925l-0.9,0.8l-3,0.7l-3.8,2.5l0.4,4.5h0.1l-0.2,1.5l1.5,1.4l-1.2,1.2l2.2,1.8l3-1.4l5.2,2.9l2.4,2.1l2.1,5.8l2.2,2.5l5.5,0.6l0.7,1.4l2.8,0.1l0.1-4.4l3.1,0.7l1.5-2.7l1.9-0.5l-0.2-0.7l2.2-2.6l-4.2-2.1l1.3-0.6l1.1-1.9l-4.7-2.3l-0.8-1.2l0.3-4.3l-1.5-4.4l-2.1-2.3l0,0l-1.3-2.6l0.2-1.5l-1.2-0.8l-0.6-2.1l-2.6-2l-2.4,2.1l-1.5,4.6l-1.9,2.4l-3.1-0.8L153.325,208.925z",
    },
    {
      id: "Z13",
      title: "Bouches-du-Rhône",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M219.725,234.925l-2.2-1.1l-2.7-2.9l-5.2-1.4l-2,2.1l-1,5.2l-1.6-0.4l-2.1,2.4l0.5,1.3l-2.9,0.9l-1.9,3    l7.1,0.2l1,1.2l-0.2,1.4l1.8,0.4l4.9-0.5l-1.1-1.2l0.6-1.2l1.4,0.1l2.3,2.7l6.6-0.7l1.9,4.4l4.9,1.2l1.4-0.4h0.1l0.2-1.6l1.2-1    l-1.1-3.2l0.5-1.3l1.4-0.2l-2.7-4.6l1-2.6l1.6-0.4l-1-1.9v-0.1l-3.3,2.2l-1.6,0.1l-6-2.4L219.725,234.925z M219.125,243.425    l1.6-0.7l-1.1,1.9l-1.6,0.6l-2.3-2.2l0.3-1.6h2L219.125,243.425z",
    },
    {
      id: "Z14",
      title: "Calvados",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M115.125,60.225h-0.1l-3.4,0.8l-2.4,2.1l-4.8,1.4l-4.3-1.8l-6.2-0.7l-4.8-1.6l-2.5,1.1l0.3,3.3l4.1,1.6    l-0.9,1.3l1.1,4.1l-4.1,2.4l0.8,1.2l-2.2,2.6l0.8,1.2l4.9,1.1l2.7-1.5l0.4-1.3l1.4,0.6l3.2-1.1l2.5,1.5l1.2-0.9l2.9,0.6l1.5-0.2    l3.5-2.7l3.5-0.8l2.9,0.5l0.3-1.1l-1.2-2.1l0.7-1.5l-1.3-4.7l1.1-1h-1.4L115.125,60.225z",
    },
    {
      id: "Z15",
      title: "Cantal",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M167.725,185.525l-3.4-0.2l-0.7-1.2l-3.8-1.8l0.4,3l-3.1-1l-0.4,1.8l-3.8,4.7l1,1.1l-1.2,1l-0.1,1.7    l-1.6,0.6l0.8,1.7l-1.7,0.9l0.5,2.7l1.9,3l-0.9,2.9l1.7,2.5l2.6-1.6l3.1,0.8l1.9-2.4l1.5-4.6l2.4-2.1l2.6,2l0.6,2.1l1.2,0.8    l-0.2,1.5l1.3,2.6l3.4-8l0.9,1.2l3.9-3.4l-1.7-4l1.7-0.1l-1.4-0.5l-1.6-4.4l-2.7-0.7v-2h-0.1l-1.8,2.1L167.725,185.525z",
    },
    {
      id: "Z16",
      title: "Charente",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M109.925,163.125l-1.8-0.2l-2.8,1.3l-0.2,1.5l-1.4,0.8l-1.3,5.8h-1.4l-1.1-1l-4.1,1.7l1.7,2.8l-1.2,1    l2.6,1.6l0.1,1.5l1.2,0.8l-0.9,1.3l0.8,1.3l-1.2,0.9l1.1,1l-0.8,1.5l3,0.6l3.1,3.1v0.1l1.1-1l2.7-0.4l2-2.9l0.4-2.8l4-3l1-2.6    l2.8-3.1l1.7-1.9l1.4-0.3l2-4l1.3-0.5l0.3-1.6l-2.4-1.8l0.1-2.6l-2.3-0.2l-0.7,1.2l-1.9,0.7l-2.1-1.9l-1.1,0.8l0.8,1.2l-1.5,0.5    l-4.6-1.5L109.925,163.125z",
    },
    {
      id: "Z17",
      title: "Charente-Maritime",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M79.025,171.825l0.6,2.2l3.9,2.4l5.2,5.6l1.1,3.7v0.1l2.9-0.4l0.3,1.4l3.3,0.8l0.2,2.9l3.1,2.2l4.7,0.5    l1-2.5v-0.2v-0.1l-3.1-3.1l-3-0.6l0.8-1.5l-1.1-1l1.2-0.9l-0.8-1.3l0.9-1.3l-1.2-0.8l-0.1-1.5l-2.6-1.6l1.2-1l-1.7-2.8l4.1-1.7    l1.1,1h1.4l1.3-5.8l-1.7-2.4l-8.8-3.3l-2.9-3.3v-1.8l-1.2-0.9l-3.2,0.1l0.7-1.4l-4.4,1.7l-2.3,4.8l1.4,0.4l2.1,4l-1.4,0.3l0.8,2.6    l-1.5,3L79.025,171.825z",
    },
    {
      id: "Z18",
      title: "Cher",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M168.325,122.725l-1-3.2l-4.5,0.8l-1.9-2.4l-6.8-1.5l-2.5,1.2l2.6,3.8l-3,2l1.1,3l-4.9,1l-0.3,1.4    l-1.4-0.1l-1.4,2.8l5.8,0.8l1.2,5.8l-1.3,2.6l1.1,1.1l-0.7,1.3l2.4,4l0.4,4.4l-0.7,1.8l2.5,0.1l-0.1-0.1l1.8-2.9l4.4-0.5l0.8-1.2    l-0.8-2.7l1.1-0.9l4.2-1.5l2.4-1.8h2l0.8-7.4l-1.1-1l-0.1-2.9l-1.6-3.8l-1.4-1.1L168.325,122.725z",
    },
    {
      id: "Z19",
      title: "Corrèze",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M139.925,179.925l-3,0.6l-2.2,2l-1.3-0.6l-0.8,1.4l1.4,2.3l-1.8,2.3l0.9,1.5l-1,0.9l1.1,0.8l0.1,1.5    l1.6,0.3l1.8,3.5l4-0.4l3.4,3.1l2.6-1.4l3.4,0.1l1.7-0.9l-0.8-1.7l1.6-0.6l0.1-1.7l1.2-1l-1-1.1l3.8-4.7l0.4-1.8l3.1,1l-0.4-3    l-0.6-3.9l0.9-1.2l-0.6-2.8l-1.2,1.2l-1.4-0.4l-1.1,1.3l-5.6-2.6l-3.5,1.7l-4.4,2L139.925,179.925z",
    },
    {
      id: "Z2A",
      title: "Corse-du-Sud",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M267.925,264.525l-0.7-1.8l-6.8-1.1l-0.2,1.4l1.3-0.5l1.9,2.1l-2.6,1.9l0.5,2.7l3.9,2.2l-1.2,0.8l-1.5,4.2    l3.5-1.3l0.5,2.9l-1.3,3.5l4.8,1l-2.5,1.7l-0.1,1.4l2.4,2.2l5.6,1.7l0.6,1.4l1.5,0.6l3-7.3l-1.7-0.2l1.3-1l1.1-2.7l-0.4-4.2    l-1.6,0.9l-1.9-0.3l-1.8-5.5l-1.4-0.5l-0.3-1.6l-2.9-3L267.925,264.525z",
    },
    {
      id: "Z2B",
      title: "Haute-Corse",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M279.025,241.325l-1.5-0.6l-1.1,1l0.2,8.7l-4.3-0.6l-1.8,2.4l-5.3,2.1l-0.4,1.5l-1.4,0.1l-1.1,1.4l0.1,1.7    l-1.9,2.5l-0.1,0.1l6.8,1.1l0.7,1.8l3,1.6l2.9,3l0.3,1.6l1.4,0.5l1.8,5.5l1.9,0.3l1.6-0.9v-3.2l2.7-5.9l-1.9-13.1l-2.1-2.8    l0.4-5.3L279.025,241.325z",
    },
    {
      id: "Z21",
      title: "Côte-d'Or",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M205.025,103.425l-1.9-0.3l-2.3,2l-3.1,0.4l-1.1,1l-0.9,1.6l1.5,1.9l-0.2,1.9l-1.4,1l0.9,1.2l-3.3,5.2    l-0.7,4l0.6,1.4l0.6,3l1.9,0.6l-0.2,1.6v0.4l1.2,1.3l2.8,0.8l0.7,1.3l2.8,1l2.8,3.1l4.3-2.1l7.6-0.7l0,0l0.5-2l3.1-3.4l0.9-4.1    l0.6-0.7l-2-4.1l-1.3-0.7l2.4-2l-0.1-1.6l-1-1.5l-1.4,0.8l-2.4,0.9v-1.4l-1.5-0.8l-4.6-1l-1.1-2.4l1.4-1.2l-0.6-1.5l-0.8-1.1    l-1.5-0.2l0.2-1.4l-1.3-0.5l-0.3-1.4L205.025,103.425z",
    },
    {
      id: "Z22",
      title: "Côtes-d-Armor",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M35.525,79.625l-1.6,0.2l0.1,1.4l1.7,2.4l-1.2,4.1l1.2,4.7l-1,2.1v-0.1l6.3,1.7l4-1.5l6.4,3.2l1.5-0.4    l0.9,3l3.1-3.8l1.8,0.4l0.7,1.4l1.5-0.5l2.4-3.6l4.5-1.3l0.8-4.4l-0.1-2.8l0,0l-1.2-0.9l0,0l-1.4-0.2l-0.8-1.1h-0.1l-2,0.2    l-0.5-1.6l-1.6,0.7l-0.5-1.3l-1.8,0.2l-5.3,4.4l-0.5-1.8l-2-1.3v-1.6l-2.3-3.9l-1.5-0.3l0.3-1.4l-1.5,0.2l-0.8,1.5l0.7-3.3    l-2.4,1.6l-0.3-1.8l-4.4,1.8l-0.7-1.2l-2.3,1.8L35.525,79.625z",
    },
    {
      id: "Z23",
      title: "Creuse",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M155.025,153.425l-2.5-0.1l-1.4,0.4l-6.6-1.4l-0.9,1.3l-5.7,0.5l-1.5,1.5l0.6,1.3l-1.5,2.6l3.4,4.9    l0.8,2.6l-0.4,1.8l1.4,0.7l0.1,1.4l3.1,0.2l2.5,1.5l0.3,3l3.5-1.7l5.6,2.6l1.1-1.3l1.4,0.4l1.2-1.2l-2.3-2.8l4.7-4.3l-1.1-3.3    l0.2-2.1l-1.8-4.1c-1.2-0.9-2.4-1.7-3.7-2.5L155.025,153.425z",
    },
    {
      id: "Z24",
      title: "Dordogne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M116.525,177.825l-1,2.6l-4,3l-0.4,2.8l-2,2.9l-2.7,0.4l-1.1,1v0.2l-1,2.5l1.9-0.3l0.6,1.4l-1.4,2.7    l-0.1,2.9l-1.1,1l4.9,1.1l1.1-1.6l1.2,1.3l-0.5,1.5l0.5,0.6l1.4,3.2l4.4-0.2l1.5-1l1,0.9l3.1-0.6l0.8,3.1l2.6-1.4l2.2,2.1l2.3-3.2    l3-1.7l-0.3-1.8l2.3-1.6l1-5.2l-1.8-3.5l-1.6-0.3l-0.1-1.5l-1.1-0.8l1-0.9l-0.9-1.5l1.8-2.3l-1.4-2.3l-2.9-1.1l1-1.4l-3.5-2.8    l-4.8,0.6v-2.4l-3.1-1.5L116.525,177.825z",
    },
    {
      id: "Z25",
      title: "Doubs",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M242.025,117.525l-2.8,0.9l-1.5,2.4l-11.1,5.1l2.6,3.9l-1.5,2.5l0.5,1.3l1-1.1l0.8,1.3l1.5-0.4l2.9,5    l1.4,0.3l2.1,2l-1,1.2l-1.8,3.7l1.5,1.5l0.5-1.5l5.8-5.4l-0.4-4.8l4.3-3l5.9-7.2l-0.1-1.5l1.1-1l-2.1-1.8l0.4-1.5l0.1-0.1    l-0.6-1.1l0.7-1.3l-3-1.3h-0.1l-3-0.4l-0.2,1.4l-1.6,0.2l-0.9,1.1L242.025,117.525z",
    },
    {
      id: "Z26",
      title: "Drôme",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M209.625,187.125l0.3,5.2l1.8,5.6l-0.7,3.1l-1.7,2.3l0.4,3.5l-1.5,3l-1,7.2l2.9,0.3l1.8,2.1l4.3-1.9    l1.2,0.7l1.3-1.1l0.2,2.9l4.7,0.4l0.1,1.4l2.6,1.2l2.1-2.4l1.2-0.1l0.5-0.1l0.1-2.3l-5-2.9l-0.8-1.3l1.6-2.5l2.1,0.7l1.3-1.3    l-1.5-1.1l1.2-3.4l2.9-0.2l0.2-1.6l-3-0.4l-2.7-2l-1.5,0.2l-0.4-8.7l-1.5,0.8l-4.1-0.9l-1.3,0.5l0.5-3.2l-1.6-3.9l-2.5-1.3    L209.625,187.125z M212.925,214.825l1.3-1.1l2.3,1.4l-1.2,1.7l-2,0.6l-1-1.1L212.925,214.825z",
    },
    {
      id: "Z27",
      title: "Eure",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M119.125,58.625l-3.3,1.3h0.1l-0.8,0.3l0.2,4.4h1.4l-1.1,1l1.3,4.7l-0.7,1.5l1.2,2.1l-0.3,1.1v1.6l3.2,0.2    l3.9,4.8l-0.9,1.1l1.8,1.1l1.2-1.3l2.9-0.6l2.7-1.6l1.4,0.8l3-0.6l-0.1-1.4l3-3.5l-0.9-2.9l1.1-1.3l2,0.1l1.9-4.8l2-1.3l-1.7-4.2    l-6.6-1.7l-2.3,3.6l-4.2,1.2l-0.7,1.4l-2.6-1.2l-1.1-1.1l0.9-1.2l-2.4-1.5l-1.7,0.4L119.125,58.625z",
    },
    {
      id: "Z28",
      title: "Eure-et-Loir",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M136.225,79.125l0.1,1.4l-3,0.6l-1.4-0.8l-2.7,1.6l-2.9,0.6l-1.2,1.3v1.6l2.4,2.2l-0.1,2.2l0.3,2.3    l-3.8,2.6l0.5,3.7h0.1l1,1.9l-0.2,0.9l3.1-0.6l6.4,5.4l2.8-1.8l1.7,1l-0.2-1.4l3.1-1.4l4.2-0.3l2.4-5.4l0.3-0.8h-0.1l-1.4-5.3    l-1.5,0.4l-1.2-1.1l-0.6-2.8l-3-2.7l-0.3-5.5l-1.8-3.3L136.225,79.125z",
    },
    {
      id: "Z29",
      title: "Finistère",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M14.725,81.025l1.3,0.6l-4.2-0.4l-1,1.2l-0.7,4.5l0.9,1.2l1.4-0.6l1.3,0.6l6.1-1.8l-1.8,0.8l-0.3,1.7    l3-0.7l-0.2,1.5l1.4,0.6l-1.5,0.3l3.1,1.3l-3-0.7l-0.6-1.2l-1.9,0.5l-2.9-0.6l-0.9-1.2l-0.7,1.9l1.2,2.6l0.9-1.4l1.4,0.1l2.7,1.6    l0.4,1.6l-0.8,1.2l-1.3-0.6l-6.2,0.6l-0.8,1.2l4.8,1.8l1.8,3.1l-0.5,2.8l3.7,0.3l1.8-2.1v-0.2l0,0l-0.4-1.4l0.5-0.1l0.2-1.6    l0.7,1.5l-0.9,0.1l-0.1,1.5l1.5,1l2.3-0.8l2.3,2.9l1.4-0.3v-1.4l0.2,1.4l1.3-0.4l-0.3,1.4l1.4,0.3l1.9-0.8l0.1-1.5l1.5,0.4    l1.2-1.4v-2.7l-4.2-0.7l-1.6-2.7l-0.3-1.7l3.4-1.6l1-2.1l-1.2-4.7l1.2-4.1l-1.7-2.4l-0.1-1.4h-0.1l-2.4-1.4l-1.4,0.3l-0.3,1.7    l-1.1-1.4l-0.8,1.6l-0.1-3.2l-7.2,2.1l-0.7-1.3L14.725,81.025z",
    },
    {
      id: "Z30",
      title: "Gard",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M208.625,221.025l-1.3-2.2l-3.1-1.7h-1.3l-1.3,1.1l-0.1-1.6l-3.5,2.2l-3.9-2.5l-1.3-2.9l-0.6,1l-1.8,1.3    l2,3.8l-0.6,1.5l0.6,1.5l-3.1,0.9l-4.3-1.1l-0.1,1.6l-1.9,0.3l-3.9-1.7l-1.1,1.9l-1.3,0.6l4.2,2.1l-2.2,2.6l0.2,0.7l0,0l1.4,0.1    l0.2,1.4l1.1-0.8l2.6,1.2l0.1-1.4l2.1-1.9l2-0.4l0.9,1.4l-0.5,1.3l3.5,0.8v1.5h1.5l3.1,3.4l0.7,1.5l-2,2.7l1.4,2.4l1.5,0.4h0.1    l1.9-3l2.9-0.9l-0.5-1.3l2.1-2.4l1.6,0.4l1-5.2l2-2.1l2.2-2.5l-3.2-3.2V221.025z",
    },
    {
      id: "Z31",
      title: "Haute-Garonne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M138.725,230.325l-5.8,2.2l1,1.1l-2.9,1.3l-0.8-1.2l-5,0.5l4.6,6.6l-3.3,3.1l-0.3,2.9l-5.7-1.3l-1.8,2.7    l-1.5,0.5l-3.5,5.6l2.2,2l-0.2,1.8l1.5-0.2l0.4,1.4l-1.3,2.6l-2.8,1.6l0.4,4l3.1,0.1l1.1-1.1l-0.1-3.3l1.2-0.6l3.4,1.1l-0.5-1.4    l1.9-2.6l1.4-0.5v-3l2-2l1.3-0.5l3.1,2l-0.1-2.8l2.6-0.8l-1.5-2.4l1.5-0.5l1.6,2.7l1.4-0.2l0.4-1.4l2.8,1.1l0.9-1.2l4.3-5l0.7,1.4    l2.6-0.9l0.2-0.4l-0.5-1.4l-7.1-4.7l0.3-2.6l-1.1-1.1l-0.1-1.4l-1.3-0.6L138.725,230.325z",
    },
    {
      id: "Z32",
      title: "Gers",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M121.725,228.725l1.7-2.3l-2.7-0.8l-1.3,0.6l-4.3-0.5l-2.5,1.4l-4.2-0.3l-0.7,1.3l-1.9-0.4l0,0l-0.1,1.5    l-1.3,0.6l-0.3-1.5l-4.7,1.4l0.3,3.2l-2.1,5.5l0.7,1.4l3.3,0.1h0.1l1.9-0.2l1,1.7l1.6,0.7l1.3,4.3l2.8-0.3l0.7,1.5l6.2,1.1    l1.5-0.5l1.8-2.7l5.7,1.3l0.3-2.9l3.3-3.1l-4.6-6.6l-1-3.5L121.725,228.725z",
    },
    {
      id: "Z33",
      title: "Gironde",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M87.725,184.925l-5.3-5.2l-0.5-1.4l-1.4,2.8l-3.1,25.1l2-3.9l2.2,2.6l0.1,1.4l-3.2-0.4l-1.6,3.4v2.4l4-1.8    l1.8,0.7l-0.7,2.7l8.6-0.4l-0.2,1.4l5.5,3.8l0.3,2.4h3.2l0.8-1.8l1.1,1.4l2.2-2l-0.7-1.3l1.5-1.1l0.2-4.5l4.3-4.3l-1.5-0.7    l0.6-1.6l1.4,0.4l0.5-1.2h1.6l-0.5-0.6l0.5-1.5l-1.2-1.3l-1.1,1.6l-4.9-1.1l1.1-1l0.1-2.9l1.4-2.7l-0.6-1.4l-1.9,0.3l-4.7-0.5    l-3.1-2.2l-0.2-2.9l-3.3-0.8l-0.3-1.4l-2.9,0.4l0.3,1.4l-1.1,1.6L87.725,184.925z",
    },
    {
      id: "Z34",
      title: "Hérault",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M161.325,245.725l-0.3,1.4l1.7,2.5l1.3-0.8l0.7,1.4l1.5-0.4l1.9-2.6l0.5,1.4l7,2.4l0.8,1l2.7-2h3.4    l3.3-3.7l5.5-4l4.2-1.1h0.1l2-2.7l-0.7-1.5l-3.1-3.4h-1.5v-1.5l-3.5-0.8l0.5-1.3l-0.9-1.4l-2,0.4l-2.1,1.9l-0.1,1.4l-2.6-1.2    l-1.1,0.8l-0.2-1.4l-1.4-0.1l0,0l-1.9,0.5l-1.5,2.7l-3.1-0.7l-0.1,4.4l-2.8-0.1c-1.2,0.8-2.3,1.5-3.4,2.2l-3.1-1.1l0.4,4.9    l-0.5,1.5L161.325,245.725z",
    },
    {
      id: "Z35",
      title: "Ille-et-Vilaine",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M85.625,94.825l0.8-1.2v-6l-2.7-1.2l-3.6,2.4l-1.3-0.5l-2.4-4.8l-5.8,0.1l0.2-2.9l-1.7,0.4l-1.9,1.9    l1.2,2.6l0.1,0.1v0.1l0.1,2.8l-0.8,4.4l-4.5,1.3l-2.4,3.6l0.5,2.1l1.7,0.1l-1.7,0.5l0.3,1.4l2.5,1.4l0.6,1.2l-0.8,1.3l1.2,0.9    l-0.5,1.6l-1.2,1.1l1.4,0.7l-1.5,0.4l0.5,2.9l2.6-1.7l6.6-0.2l0.8-1.5l4.5-1.9l3.4,1.8l0.2-1.1l1.8-4.3l2.7-1.1L85.625,94.825z",
    },
    {
      id: "Z36",
      title: "Indre",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M139.225,128.725l-1.3-0.6l-3,1.6l0.5,2.4l-1.2,1.1l-1.3,1.7l-1.2-0.8l-2.6,1.3l-1.6,6l0.4,1.3l-2.7,0.3    l1,1.5l-0.4,2.9l2.4,2.2h1.4l1.3,2.7l1.3,0.6l-0.8,1.4l1.3,0.6l2.9-0.6l0.8,1.3l1.5-1.5l5.7-0.5l0.9-1.3l6.6,1.4l1.4-0.4l0.7-1.8    l-0.4-4.4l-2.4-4l0.7-1.3l-1.1-1.1l1.3-2.6l-1.2-5.8l-5.8-0.8l1.4-2.8l-2.6-1.7l-3.2,0.4L139.225,128.725z",
    },
    {
      id: "Z37",
      title: "Indre-et-Loire",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M129.525,117.625l-2.9-1.7l-1.2,0.9l0.1-2.8l-5.2-0.2l-3.8,2.2l-1.4-0.1l0.2,1.6l-3-1.3l-0.4,1.2l-0.9,4.7    l-2.8,7.6v0.1l2.7,1.6l0.1,1.6l1.5-0.2l0.7,3.1l1.4,0.7l5-0.2l-0.8-1.5l2.7,1.5l0.1,1.6l3.6,5l2.7-0.3l-0.4-1.3l1.6-6l2.6-1.3    l1.2,0.8l1.3-1.7l1.2-1.1l-0.5-2.4l-2.1-3.4l-1.4,0.7l-1.1-1l0.6-4.5l-1.7-2.6L129.525,117.625z",
    },
    {
      id: "Z38",
      title: "Isère",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M220.125,169.525l-1.5,2.5l-3-1l0.2,3.6l-1.3,2.5l-3.4,0.7l-1.3,1.3l0.9,1.2l-2.2,2l0.1,0.5v2.2l1,2.1    l4.5-1.5l2.5,1.3l1.6,3.9l-0.5,3.2l1.3-0.5l4.1,0.9l1.5-0.8l0.4,8.7l1.5-0.2l2.7,2l3,0.4l0.4-1.6l2.8-0.6l0.4-1.4l6.3-1.9l1.6,0.5    l0.2-2.9l-2.2-1.5l-0.9-2.1l0.8-1.9l-2.6-0.8l-0.5-2.7l1.1-2.7l-1.3-3.2l-4.7-1l-0.2,1.4l-3.1-0.1l-3.2-5.3l-5.6-7.6    L220.125,169.525z",
    },
    {
      id: "Z39",
      title: "Jura",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M222.725,124.825l-0.6,0.7l-0.9,4.1l-3.1,3.4l-0.5,2l0.2,1.5l4.1,2.2l-2.5,1.8l1.3,0.8l1,4.4l-1.4,2.5    l0.4,2.6l-1.3,0.6l2.1,3.9h1.5v1.5l1.5-0.1l2-2.2l1.6,0.8l0.2,1.4l2-0.1l2.7-1.6l2.4-3.3l-0.1-0.1l1.3-4.5l0,0l-1.5-1.5l1.8-3.7    l1-1.2l-2.1-2l-1.4-0.3l-2.9-5l-1.5,0.4l-0.8-1.3l-1,1.1l-0.5-1.3l1.5-2.5l-2.6-3.9l-2.8-0.1L222.725,124.825z",
    },
    {
      id: "Z40",
      title: "Landes",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M90.425,214.325l0.2-1.4l-8.6,0.4l0.7-2.7l-1.8-0.7l-4,1.8l-5.4,25l-2,3.5l2.6,1.2l5.5-1.2l0.6,1.4    l1.3-0.7l1.3,0.7l0.8-1.1l4.9-1l1.3,1.2l1.1-0.8l1.1,1l2.4-0.9l1.6,0.5l4.3-0.7l-0.7-1.4l2.1-5.5l-0.3-3.2l4.7-1.4l0.3,1.5    l1.3-0.6l0.1-1.5l0,0l-0.3-1.3l1.8-2.6l-5.8-1.4l-0.2-2.3l-1.1-1.4l-0.8,1.8h-3.2l-0.3-2.4L90.425,214.325z",
    },
    {
      id: "Z41",
      title: "Loir-et-Cher",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M143.225,113.225l-1.3-0.7l-1.5,0.6l-0.8-2.8l0.8-1.6l-1.1-3.5l-1.7-1l-2.8,1.8l-6.4-5.4l-3.1,0.6v0.1    l-1,1l0.9,1.1l0.1,2.8l-3.1,5.3l-1.6,0.5l-0.3,1.8l5.2,0.2l-0.1,2.8l1.2-0.9l2.9,1.7l-0.3,1.3l1.7,2.6l-0.6,4.5l1.1,1l1.4-0.7    l2.1,3.4l3-1.6l1.3,0.6l0.7-1.3l3.2-0.4l2.6,1.7l1.4,0.1l0.3-1.4l4.9-1l-1.1-3l3-2l-2.6-3.8l2.5-1.2l-0.4-1.3l-7.6-0.8l-0.9,1.1    l-1.5-0.3L143.225,113.225z",
    },
    {
      id: "Z42",
      title: "Loire",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M199.925,159.025L199.925,159.025l-2.8,1.9l-1.1-1l-4,0.7l-2.5-1.6v-1.5l-2,0.6l-0.2,1.5l0.7,6.2l-2.7,2.5    l1.2,1.3l-1,3.1l2.3,3.9l2.3,1.9l1.3,3.7l-1.4,3.6l1.3-0.4l1.2,1l1.4-0.2l3.1-1.5l3,1.1l0.3,2.7l1.4-0.5l1.1,1.2l1.7-0.4l1.6-2.4    l2.5-1.4v-2.2l-0.1-0.5l-2.2-1.3l-0.5-1.5l-2.9-1.1l-2.3-2l-0.6-1.5l0.6-1.3l-1.1-0.8l0.9-2.9l-2.7-3.4l0.9-4.2l2.4-1.8    L199.925,159.025z",
    },
    {
      id: "Z43",
      title: "Haute-Loire",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M181.825,184.525h-4.3l-4.7,1.6v2l2.7,0.7l1.6,4.4l1.4,0.5l-1.7,0.1l1.7,4l1.1,0.9l0.8,2.8l3.2,0.6    l0.1-1.4l1.4,0.1l4.5,3.9l3.4-3.2l2.9-1l1.7-2.9l1.7-0.2l-0.3-1.4l1.3-1.2l0.7-2.6l1.3,0.6v-1.5l0.5-2.1l-1.1-1.2l-1.4,0.5    l-0.3-2.7l-3-1.1l-3.1,1.5l-1.4,0.2l-1.2-1l-1.3,0.4l-5.6,0.3L181.825,184.525z",
    },
    {
      id: "Z44",
      title: "Loire-Atlantique",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M78.425,108.225l-4.5,1.9l-0.8,1.5l-6.6,0.2l-2.6,1.7l-0.2,3l-7.7,2.2l-2.2,2.2l1.2,3.1l3.0.5l1,1l2.5-1.8    l3.6-0.3l-3.4,1.2l-0.3,3.4l-1.5,0.6l3,1.1l2.4,2.5l3,3.1l5.8,2.5l0.2-4.8l1.6-0.1v1.6l1.2,0.8l1.2-1l-0.2-1.5l1.5-0.4l0.8-1.2    l2.4,1.8l-0.2-1.5l-1.4-0.7l1.4-2.3l-0.4-1.4l-1.8-2.3l-1.5-0.4l1.4-1.1l7.2-1.1l-0.1-2.9l-4.3-1.7l0.9-1.2l1.4,0.2l-3.6-6.6    L78.425,108.225z",
    },
    {
      id: "Z45",
      title: "Loiret",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M168.525,99.725l-5.6,1.3l-4.6-0.3l1.3-0.8l0.3-1.4l-2.1-2.1l-0.3-1.5l-1.9-0.3l-1.4,0.9l-0.7-1.4l-1,1.4    l-3.4,0.4l-0.3,0.8l-2.4,5.4l-4.2,0.3l-3.1,1.4l0.2,1.4l1.1,3.5l-0.8,1.6l0.8,2.8l1.5-0.6l1.3,0.7l0.5,1.9l1.5,0.3l0.9-1.1    l7.6,0.8l0.4,1.3l6.8,1.5l1.9,2.4l4.5-0.8l0.7-1.2l1.5-0.4l-1.2-3.4l-1.5-1.4l2.7-1.7l0.8-1.2l-0.6-1.4l2.3-2.5l-0.2-2.9    L168.525,99.725z",
    },
    {
      id: "Z46",
      title: "Lot",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M140.725,196.025l-4,0.4l-1,5.2l-2.3,1.6l0.3,1.8l-3,1.7l-2.3,3.2l-1.7,1.6l1.2,4.5l1.6-0.2l-1.1,1.1    l2.3,2l1.4,0.5l1.2-0.7l0.2,2l4.4-1.3l1.3,1.2l3.7-2.8l3.1-0.4l-0.4-4.5l3.8-2.5l3-0.7l0.9-0.8l-1.7-2.5l0.9-2.9l-1.9-3l-0.5-2.7    l-3.4-0.1l-2.6,1.4L140.725,196.025z",
    },
    {
      id: "Z47",
      title: "Lot-et-Garonne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M128.425,209.925l-2.2-2.1l-2.6,1.4l-0.8-3.1l-3.1,0.6l-1-0.9l-1.5,1l-4.4,0.2l-1.4-3.2h-1.6l-0.5,1.2    l-1.4-0.4l-0.6,1.6l1.5,0.7l-4.3,4.3l-0.2,4.5l-1.5,1.1l0.7,1.3l-2.2,2l0.2,2.3l5.8,1.4l-1.8,2.6l0.3,1.3l1.9,0.4l0.7-1.3l4.2,0.3    l2.5-1.4l4.3,0.5l1.3-0.6l1.8-2.4l1.4,0.1l-0.7-1.4l2.2-2.7l-1.2-0.7v-1.5l3.7-1l-1.2-4.5L128.425,209.925z",
    },
    {
      id: "Z48",
      title: "Lozère",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M183.725,200.725l-0.1,1.4l-3.2-0.6l-0.8-2.8l-1.1-0.9l-3.9,3.4l-0.9-1.2l-3.4,8l0,0l2.1,2.3l1.5,4.4    l-0.3,4.3l0.8,1.2l4.7,2.3l3.9,1.7l1.9-0.3l0.1-1.6l4.3,1.1l3.1-0.9l-0.6-1.5l0.6-1.5l-2-3.8l1.8-1.3l0.6-1l-2.2-4.2l-1-4.5    l-4.5-3.9L183.725,200.725z",
    },
    {
      id: "Z49",
      title: "Maine-et-Loire",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M78.925,124.425l1.5,0.4l1.8,2.3l0.4,1.4l-1.4,2.3l1.4,0.7l0.2,1.5l5.3,1.9l6.1-0.4l1.9-2.4l2.8-0.4    l4.8-0.1l1.1,1.1l3.4-2.9v-0.1l2.8-7.6l0.9-4.7l0.4-1.2h-3.7l-2.6-1.7l-1.6,0.7l-1-1l-0.1-1.8l-3.3,0.1l-0.3-1.3l-2.6-0.9    l-2.9,1.4l-1.5-0.2l-4.4-1.8l-1.1,0.9l-5.2-1.7l-0.2,1.1l3.6,6.6l-1.4-0.2l-0.9,1.2l4.3,1.7l0.1,2.9l-7.2,1.1L78.925,124.425z",
    },
    {
      id: "Z50",
      title: "Manche",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M84.525,52.325l-0.5-1.3l-3.3-0.4l-1.2,0.9l-3.3,0.2l-5.7-2.3l-0.1,1.4l1.7,0.9l0.2,2.5l-0.9,1l1.2,5.1    l4.3,4.6l-0.7,5l1,1.4l-0.2,3l-1.2,2.8l2.1,4.7l1.3,0.7l1.3-0.8l-0.5,1.5l-3.6,0.3l2.4,4.8l1.3,0.5l3.6-2.4l2.7,1.2l4.3,0.4    l1.8-2.3l1-3.3l-2.1-2.1l-4.9-1.1l-0.8-1.2l2.2-2.6l-0.8-1.2l4.1-2.4l-1.1-4.1l0.9-1.3l-4.1-1.6l-0.3-3.3h-0.1l-3.2-5.2    L84.525,52.325z",
    },
    {
      id: "Z51",
      title: "Marne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M182.825,64.925l1.6,4l-2.7,1.1l0.8,1.2l-0.5,1.4l1.3,0.6l-3.8,5.1l-0.3,1l-1.2,1.1l1.7,4.2l1.3,0.8    l1.6,2.3l4.5,0.6v-1.7l4.7-3.8l3.3-0.4l1.6,0.2l0.1,3.1l1.3,1c1,0.4,1.9,0.8,2.9,1.1l1.2-0.7l1.7,0.5h2.3l-0.4-1.4l1.2-1.1    l-1.1-1.3l4.3-1.1l-0.2-2l-1.7-2.3l0.6-2.9l1.6-0.6l0.2-1.9l-1.2-0.7l0.5-2l-1.1-4.5l-10.1-1l-4.9-3.3l-3.4-0.5l-0.4,1.5l-2.7-1    l-4,2L182.825,64.925z",
    },
    {
      id: "Z52",
      title: "Haute-Marne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M210.225,82.725l-4.3,1.1l1.1,1.3l-1.2,1.1l0.4,1.4h-2.3l-0.4,1.7l1.2,2.6l2.8,1.5l0.3,5l-0.1,1.3    l-2.8,1.3l0.1,2.4l1.8,0.3l0.3,1.4l1.3,0.5l-0.2,1.4l1.5,0.2l0.8,1.1l0.6,1.5l-1.4,1.2l1.1,2.4l4.6,1l1.5,0.8v1.4l2.4-0.9l0.6-1.3    l3.3-1.9l1.1,0.9l1.5-0.5l0.2-4.2l1-1.2l1.5,0.2l1.1-1.6l-0.1-0.7v-0.1l-0.7-1.2l-1.3,0.5l-0.1-1.6l-2.7-2.3l0.8-4.3l-6.4-4.7    l1.2-1.2l-8.4-5.2L210.225,82.725z",
    },
    {
      id: "Z53",
      title: "Mayenne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M86.425,93.625l-0.8,1.2l0.9,8.7l-2.7,1.1l-1.8,4.3l5.2,1.7l1.1-0.9l4.4,1.8l1.5,0.2l2.9-1.4l2.6,0.9    l-0.8-2.8l2.7-3.9l-0.6-1.7l2.5-2.3l-0.7-1.4l2.2-2l-0.1-3.5l2.2-1.3v-1.5l-1.7-0.8l-0.9-3l-1.4-0.1l-0.3,1.3h-4.6l-3.4,1.7    l-1.2-0.9l-1.2,0.8l-1.7-1.8l-4.3-0.4V93.625z",
    },
    {
      id: "Z54",
      title: "Meurthe-et-Moselle",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M253.125,85.325l-1-1.3l-7.3-2.7l-2.6-1.9l-4.2-0.8l-1.4-2.6l0.2-1.4l-5.8-2l-2.2-3.7l1.5-0.1l-0.5-2.8    l0.8-1.5l-0.6-1.8l-1.3-0.8l-1.2-4.7l-0.1-0.1l0,0l-1.7-1.4h-3.4l-0.6,1.4l-2.7,0.3l0.7,2.7l2.7-1.1l2.2,2.5l0.6,1.3l-0.8,3    l2.5,5.7l-0.5,1.4l-1.1,6.9l0.6,4.3l-0.8,1.4l1.3,1l-0.1,1.4l2.8,0.3v1.5l2,2.4l2.9-0.4l0.6-1.4l2.7-1l1.2,0.9l3-0.5l2.6-1.9    l0.8,1.4l3,0.8l5.3-3.8l0.8-0.2L253.125,85.325z",
    },
    {
      id: "Z55",
      title: "Meuse",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M224.425,65.825l0.8-3l-0.6-1.3l-2.2-2.5l-2.7,1.1l-0.7-2.7h-0.1l-1.7-3.6h-0.1l-0.8,0.6l-1.1,1.4    l-2.8-1.1l-1,1l0.5,4.4l-1.6,2.6l0.6,1.2l-2,1.8v0.1l1.1,4.5l-0.5,2l1.2,0.7l-0.2,1.9l-1.6,0.6l-0.6,2.9l1.7,2.3l0.2,2l1.7,2.6    l8.4,5.2l3.5-1.7l1.5,0.3l1-1.2l0.1-1.4l-1.3-1l0.8-1.4l-0.6-4.3l1.1-6.9l0.5-1.4L224.425,65.825z",
    },
    {
      id: "Z56",
      title: "Morbihan",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M31.325,96.125l0.3,1.7l1.6,2.7l4.2,0.7v2.7l-1.2,1.4l-1.5-0.4l0.4,3.9l1.3,0.7l1.3-0.6l-0.2-3l0.2,1.5    l1.6,0.3l-1.1,1.1l0.7,1.3l1.9,0.8l1.3-0.6l-1.2,0.6l0.1,1.4l1.1,1.4l-0.4,1.9l1.1,1.1l-0.6-1.4l1.3-1.2l1.6-0.4l1.2,0.9l-0.6-2.9    l1.6,2.4l0.7-1.2l1.9,0.3l1,1.2l-1,1.2l-3.1-0.4l2.4,1.9l6.2-0.5l1.3,0.8h-1.5l0.8,1.3l7.7-2.2l0.2-3l-0.5-2.9l1.5-0.4l-1.4-0.7    l1.2-1.1l0.5-1.6l-1.2-0.9l0.8-1.3l-0.6-1.2l-2.5-1.4l-0.3-1.4l1.7-0.5l-1.7-0.1l-0.5-2.1l-1.5,0.5l-0.7-1.4l-1.8-0.4l-3.1,3.8    l-0.9-3l-1.5,0.4l-6.4-3.2l-4,1.5l-6.3-1.7v0.1L31.325,96.125z",
    },
    {
      id: "Z57",
      title: "Moselle",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M252.525,68.625l0.4,1.4l4.5,2.3l4.5-0.1l0.7-1.3l0.7-2.2h-0.1l-4.2-3.7l-3.1,2.1l-3.5-0.6l-1.3,0.6    l-1.1-2l-1.5-0.7l-1.4,0.8l-0.1,1.5l-1.4-0.4l-4-4.5l-0.9-3l-1.5-1l-5.5-1.3l-2.2,1.8h-1.4l-2.6-1.1l1.2,4.7l1.3,0.8l0.6,1.8    l-0.8,1.5l0.5,2.8l-1.5,0.1l2.2,3.7l5.8,2l-0.2,1.4l1.4,2.6l4.2,0.8l2.6,1.9l7.3,2.7l1,1.3l1.7,0.2l1.1-0.9l1.5-6.7l-2.2-1.7    l-1.4-0.2l-0.7,1.3l-1.2-0.8l0.9-1.3l-2.8-1.2L252.525,68.625z",
    },
    {
      id: "Z58",
      title: "Nièvre",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M187.725,122.125l-2.8,0.4l-2.8-1.5l-1.9-2.8l-2.3,2.2h-3l-3.8-2.4h-1.6v-0.1l-1.5,0.4l-0.7,1.2l1,3.2    l-0.9,2.9l1.4,1.1l1.6,3.8l0.1,2.9l1.1,1l-0.8,7.4l2.9,3.1l3.2,0.1l1.4-0.8l2.2,1.8l3-3l3.5,1.4l5.6-2.8l-0.3-1.5l0.9-1l-2-3.5    l1.1-0.9l-0.2-2.9l1.4-0.5v-0.7l1.3-0.2l0.6-0.1v-0.4l0.2-1.6l-1.9-0.6l-0.6-3l-2.8,0.2l-0.7-1.4l-1.4,0.4L187.725,122.125z",
    },
    {
      id: "Z59",
      title: "Nord",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M173.825,22.925l0.3-1.5l-2-2.2l-2.9,0.3l-2.8,1.9l-1.5-0.7l-1.4-2.5h-1.7l-0.6-1.4l0.5-2.9l-1.2-3    l-1.4-0.8l-8.2,2.1l3.4,6.3l2.7,1.3l-0.3,1.4l2,2.1l5.8,0.2l0.9-1.1l0.6,1.3l-0.5,3l3,0.5l1.9,2.7l-1.5,0.8l1.9,2.6l-0.3,1.4    l1.5,0.1l0.9,1.2l-1.8,5.3l1.6,1.4l6.3-0.4l1.3-1.1l1.2,0.9l2.6-0.9l7.8,2.1l1.6-3l-1.5-2.6l1-3.3l-1.4,0.3l-2-2.4l-3,0.5    l-2.8-0.6l-1.1,1l-1-4.5l-2.8-0.7l-0.5-1.3l-1.5,1l-1.5-0.5L173.825,22.925z",
    },
    {
      id: "Z60",
      title: "Oise",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M171.725,52.125l-5.1,0.7l-4.3,3.4l-6.4-3.3l-7.2-1.1l-1.5,0.6l-2.1-1.9l-1.5,2.4l0.7,4.3l-0.7,4l1.7,4.2    l-2,1.3l0.8,1.6l2.8,0.6l3.2-0.9l4.6,1.1l1.1-0.8l5.4,3.2l3.5,0.5l1-1.1l1.2,0.8l4.1-1.5l1.1-0.8l-0.3-1.4l-1.4-0.6l-1.2,0.8    l-0.4-1.5l1.5,0.1l-1.4-2.3l1.3-0.8l1.2-2.8l1.3-0.6l-1.1-0.9l0.3-2.2L171.725,52.125z",
    },
    {
      id: "Z61",
      title: "Orne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M94.125,78.825l-2.7,1.5l2.1,2.1l-1,3.3l-1.8,2.3l1.7,1.8l1.2-0.8l1.2,0.9l3.4-1.7h4.6l0.3-1.3l1.4,0.1    l0.9,3l1.7,0.8v1.5h1.5l5.5-2.9l1.3,0.7l0.8,4.2l3.7,2.5l1.4-0.5l1.6,1.8l1.6,0.3h-0.1l-0.5-3.7l3.8-2.6l-0.3-2.3l0.1-2.2    l-2.4-2.2v-1.6l-1.8-1.1l0.9-1.1l-3.9-4.8l-3.2-0.2v-1.6l-2.9-0.5l-3.5,0.8l-3.5,2.7l-1.5,0.2l-2.9-0.6l-1.2,0.9l-2.5-1.5    l-3.2,1.1l-1.4-0.6L94.125,78.825z",
    },
    {
      id: "Z62",
      title: "Pas-de-Calais",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M165.425,22.425l-0.9,1.1l-5.8-0.2l-2-2.1l0.3-1.4l-2.7-1.3l-3.4-6.3h-0.1l-6.7,2.1l-2.9,2.4v9l1.4,1.8    l-1.4-0.8l-0.3,2.9l0.2,1.9l1.2,0.8l6.1,0.5l0.4,1.5l3.6,2.9l6-1.2l-1.3,2.8l0.9,1.6l1.2-1.6l4.2,1.8l0.4-1.4l1.4,2.3l1.2-0.8    l0.4,1.5l4.3-0.9l1.8-5.3l-0.9-1.2l-1.5-0.1l0.3-1.4l-1.9-2.6l1.5-0.8l-1.9-2.7l-3-0.5l0.5-3L165.425,22.425z M171.025,37.725    l-0.3,1.3l-1.4,0.4L171.025,37.725z",
    },
    {
      id: "Z63",
      title: "Puy-de-Dôme",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M185.525,172.725l1-3.1l-1.2-1.3l-1.8-2.2l-3.2-0.2l-0.7-1.6l-6.5-0.3l-0.9-1.2l-1.6,0.2l-2.2-2.2l0.6-1.4    l-1.1-0.9l-5.6,4l-1.3-0.6l-0.2,2.1l1.1,3.3l-4.7,4.3l2.3,2.8l0.6,2.8l-0.9,1.2l0.6,3.9l3.8,1.8l0.7,1.2l3.4,0.2l3.2,2.7l1.8-2.1    h0.1l4.7-1.6h4.3l2.6,1.6l5.6-0.3l1.4-3.6l-1.3-3.7l-2.3-1.9L185.525,172.725z",
    },
    {
      id: "Z64",
      title: "Pyrénées-Atlantiques",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M88.925,239.925l-1.1,0.8l-1.3-1.2l-4.9,1l-0.8,1.1l-1.3-0.7l-1.3,0.7l-0.6-1.4l-5.5,1.2l-2.6-1.2    l-2.3,3.2l-3.1,2.3l2.9,1.4l0.2,1.5l1.2-1l3.2,0.6l0.8,1.5l-1,2.9l-1.3,1.2l0.6,1.4l2,0.5l0.2-1.8l1.5-0.5l0.1,1.7l3,1.6l4.8,1.8    l3.1-0.1l1.3,2.3l3.9,3.3l1.2-0.9l1.2,0.7l2.9-1.5h0.1l0.6-4.3l0.8-1.4l1.7-0.4l-0.2-1.5l4-4.6l-0.4-1.6l1.3-0.7l-0.2-3.6h-1.4    l0.7-1.4l-1.2-2.9h-0.1l-3.3-0.1l-4.3,0.7l-1.6-0.5l-2.4,0.9L88.925,239.925z M101.525,248.625l0.1,1.4l-1.2-0.8L101.525,248.625z",
    },
    {
      id: "Z65",
      title: "Hautes-Pyrénées",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M103.625,239.725l-1.9,0.2l1.2,2.9l-0.7,1.4h1.4l0.2,3.6l-1.3,0.7l0.4,1.6l-4,4.6l0.2,1.5l-1.7,0.4    l-0.8,1.4l-0.6,4.3l5.6,5l6.1-0.8l1.2,1h5l-0.4-4l2.8-1.6l1.3-2.6l-0.4-1.4l-1.5,0.2l0.2-1.8l-2.2-2l3.5-5.6l-6.2-1.1l-0.7-1.5    l-2.8,0.3l-1.3-4.3l-1.6-0.7L103.625,239.725z",
    },
    {
      id: "Z66",
      title: "Pyrénées-Orientales",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M148.525,269.225l-0.6,1.3l-4.5,1.3l-0.9,2.4l4.4,1.3l1.2,2.8l1.6,0.1l2.2-2h1.6l4.7,1l2.3,1.8l3.1-0.2    v-1.5l4.1-2.1l3.1-0.6l4.3,1.2l-2.9-3.6l-0.2-8.8l-3.9-2.4l-4.2,2.8l-6.8-0.5l-1.3,0.6l0.7,3l-4.3,2L148.525,269.225z",
    },
    {
      id: "Z67",
      title: "Bas-Rhin",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M274.825,70.525l-4.3-1.5l-7.2-0.3l-0.7,2.2l-0.7,1.3l-4.5,0.1l-4.5-2.3l-0.4-1.4l-2.5,5.4l2.8,1.2    l-0.9,1.3l1.2,0.8l0.7-1.3l1.4,0.2l2.2,1.7l-1.5,6.7l-1.1,0.9l-1.7-0.2l0.9,0.7l0,0l-0.6,5l2.5,1.1l1.6,0.1l4.2,2.8l0.2,1.4l2.1,1    l2.9-6.9l0.7-7.8l5.3-6.9L274.825,70.525z",
    },
    {
      id: "Z68",
      title: "Haut-Rhin",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M261.725,95.025l-4.2-2.8l-1.6-0.1l-0.5,0.4l-2.1,6.8l-2.3,3.9l-0.1,3.1l-1.3,1.2l3.8,2.5l0.2,4.5l1.4-0.1    l1,2.6l-1.9,0.2v0.1l1.8-0.3l1.4,0.4l-0.4,1.4l3.8,0.1l1.3-0.5l0.3-1.6h1.5l0.1-1.6l1.1-1l-1.5-3.9l1.4-7.7l-1-5.1l0.1-0.1l-2.1-1    L261.725,95.025z",
    },
    {
      id: "Z69",
      title: "Rhône",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M202.325,157.425l-1-0.8l-1.2,0.9l-0.2,1.5l1.1,1.5l-2.4,1.8l-0.9,4.2l2.7,3.4l-0.9,2.9l1.1,0.8l-0.6,1.3    l0.6,1.5l2.3,2l2.9,1.1l0.5,1.5l2.2,1.3l2.2-2l-0.9-1.2l1.3-1.3l3.4-0.7l1.3-2.5l-0.2-3.6l-3.2,0.3l-1.8-2.9l-1.5,0.3l-1.5-4.6    l0.8-4l-2.9-3.8l-0.8,1.1l-1.2-0.8L202.325,157.425z",
    },
    {
      id: "Z70",
      title: "Haute-Saône",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M239.925,103.825l-2.9,0.8l-3.5-2.1l-4,2.9l0.1,0.7l-1.1,1.6l-1.5-0.2l-1,1.2l-0.2,4.2l-1.5,0.5l-1.1-0.9    l-3.3,1.9l-0.6,1.3l1.4-0.8l1,1.5l0.1,1.6l-2.4,2l1.3,0.7l2,4.1l1.1,1l2.8,0.1l11.1-5.1l1.5-2.4l2.8-0.9l1.4,0.4l0.9-1.1l1.6-0.2    l0.2-1.4l3,0.4h0.1l-1.3-4.9l1.2-2.9l-4.2-3.3l-2.6,1.1L239.925,103.825z",
    },
    {
      id: "Z71",
      title: "Saône-et-Loire",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M217.625,135.025L217.625,135.025l-7.6,0.7l-4.3,2.1l-2.8-3.1l-2.8-1l-0.7-1.3l-2.8-0.8l-1.2-1.3l-0.6,0.1    l-1.3,0.9l-1.4,0.5l0.2,2.9l-1.1,0.9l2,3.5l-0.9,1l0.3,1.5l-5.6,2.8l-3.5-1.4l3.3,6.8l3.9,1.5l0.9,1.1l-0.3,2.8l-1.8,2.3v1.5    l2.5,1.6l4-0.7l1.1,1l2.8-1.9l0,0l0.2-1.5l1.2-0.9l1,0.8l1.2-0.8l1.2,0.8l0.8-1.1l2.9,3.8l2.9-10.5l2.9,0.8l2.7-0.6l2.5,1.6    l1.3-0.6l-0.4-2.6l1.4-2.5l-1-4.4l-1.3-0.8l2.5-1.8l-4.1-2.2L217.625,135.025z",
    },
    {
      id: "Z72",
      title: "Sarthe",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M108.625,92.325h-1.5l-2.2,1.3l0.1,3.5l-2.2,2l0.7,1.4l-2.5,2.3l0.6,1.7l-2.7,3.9l0.8,2.8l0.3,1.3l3.3-0.1    l0.1,1.8l1,1l1.6-0.7l2.6,1.7h3.7l3,1.3l-0.2-1.6l1.4,0.1l3.8-2.2l0.3-1.8l1.6-0.5l3.1-5.3l-0.1-2.8l-0.9-1.1l1-1v-0.1l0.2-0.9    l-1-1.9l-1.6-0.3l-1.6-1.8l-1.4,0.5l-3.7-2.5l-0.8-4.2l-1.3-0.7L108.625,92.325z",
    },
    {
      id: "Z73",
      title: "Savoie",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M232.425,169.925l-1.3-3.3l-1.1,6.8l-2.9,3.3l3.2,5.3l3.1,0.1l0.2-1.4l4.7,1l1.3,3.2l-1.1,2.7l0.5,2.7    l2.6,0.8l3.9,1.7l1-1l3-0.6l0,0l9.7-4.1l1-1.9l-0.7-1.3l1.5-2.6l-3.7-2.5l-1.1-2.8l0.3-1.5l-2.7-1.2l-1.5-1.3l-0.2-1.5l-1.1,0.8    l-3.1-1.2l-1.1-0.8l0.3-1.5l-1.2-0.9l-4.8,7l-2.2-1.8l-1.7-0.4l-1.3,0.8l-1.5-0.9l-0.6-1.2L232.425,169.925z",
    },
    {
      id: "Z74",
      title: "Haute-Savoie",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M242.325,153.625l-1.7-0.7l-1.4,2.9l1.2,1.5l-3.5,2.7l-3.4,0.5l-1.3,1.4h-1.5l0.4,4.7l1.3,3.3l1.4,0.5    l0.6,1.2l1.5,0.9l1.3-0.8l1.7,0.4l2.2,1.8l4.8-7l1.2,0.9l-0.3,1.5l1.1,0.8l3.1,1.2l1.1-0.8v-0.1l0.1-0.1l4.1-4l0.5-1.5l-1.7-2.3    l-3.9-3.7l1.2-3.1l-1.9-2.7l0.2-1.4l-1.6-0.3l-4.3,0.2L242.325,153.625z",
    },
    {
      id: "Z75",
      title: "Paris",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M262.3,22.6v1.2l-3.8,2.8h-2.4l-1.6,2l0.1,1.1l1.1,0.9h2.4v0.9l2.8,1.1l2.3,2.2h2.4h1.9v-0.4h2.8l0.3-0.8    h2.6l1.2,1.8l0.7-0.3v-2.5l-2.1-0.2l-2.9-1.2l0.9-2.6v-1.9v-0.1l-2.7-2.6l-2.9-1.4H262.3z M159,79.9l-0.8-0.9h-0.1l-0.3-1.4l-1.6-0.2l-1.3,0.8l1.5,1.8L159,79.9z",
    },
    {
      id: "Z76",
      title: "Seine-Maritime",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M137.125,41.025l-0.1,0.1l-5.4,3.8l-11.1,2.7l-7.1,3.9l-2.9,6.3l0.9,1.1l4.4,1l3.3-1.3l3.8,2.5l1.7-0.4    l2.4,1.5l-0.9,1.2l1.1,1.1l2.6,1.2l0.7-1.4l4.2-1.2l2.3-3.6l6.6,1.7l0.7-4l-0.7-4.3l1.5-2.4l-1.7-4.2L137.125,41.025z",
    },
    {
      id: "Z77",
      title: "Seine-et-Marne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M165.725,70.925l-1,1.1l-3.5-0.5l0.2,1l-0.9,1.1v0.1l0.8,2.5l-0.7,1.3l0.7,2.3v0.1l0.1,1.4l-0.5,2.1    l0.2,0.5l-1.9,8.6l-1.7,2.4l0.3,1.5l2.1,2.1l-0.3,1.4l-1.3,0.8l4.6,0.3l5.6-1.3l1.9-2.7l0.1-2.9l7.7-1.5l-0.2-4.3l1.5-0.4l1.5-2.5    l-1.3-0.8l-1.7-4.2l1.2-1.1l0.3-1l-4.7-3l-1.9-2.1l0.3-1.4l-0.9-1.2l-1.3-0.4l-4.1,1.5L165.725,70.925z",
    },
    {
      id: "Z78",
      title: "Yvelines",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M150.625,73.725l-4.5-1.8l-4.7-0.4l-2-0.1l-1.1,1.3l0.9,2.9l1.8,3.3l0.3,5.5l3,2.7l0.6,2.8l1.2,1.1    l1.5-0.4l2.3-4.3l-0.3-1.4l0.8-1.3l3.5-2.7l-1.5-2.6l1-1.6h-0.1L150.625,73.725z",
    },
    {
      id: "Z79",
      title: "Deux-Sèvres",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M98.925,131.725l-2.8,0.4l-1.9,2.4l-6.1,0.4l1.1,2.8l2.1,2.1l2.4,9l-0.1,3.6l1.2,1l-4.5,2.3v1.8l2.9,3.3    l8.8,3.3l1.7,2.4l1.4-0.8l0.2-1.5l2.8-1.3l1.8,0.2l0.4-0.3l0.5-1.5l-2.3-1.5l1.6-4.2l-3.2-0.1l0.1-1.4l-1.1-1l-0.3-2.9l1.7-2.8    l-0.7-1.5l-1.3,0.5l2.2-7.1l-1.4-0.6l0.5-1.5l-1.8-4.5l-1.1-1.1L98.925,131.725z",
    },
    {
      id: "Z80",
      title: "Somme",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M166.825,42.225l-0.4-1.5l-1.2,0.8l-1.4-2.3l-0.4,1.4l-4.2-1.8l-1.2,1.6l-0.9-1.6l1.3-2.8l-6,1.2l-3.6-2.9    l-0.4-1.5l-6.1-0.5l-1.9,0.7l-0.2,1.5l1.6,2.8l-1.4-0.8l-3.3,4.5l6.3,5.3l1.7,4.2l2.1,1.9l1.5-0.6l7.2,1.1l6.4,3.3l4.3-3.4    l5.1-0.7l-1.1-3.5l1.9-4.1l0.2-1.8l-1.6-1.4L166.825,42.225z",
    },
    {
      id: "Z81",
      title: "Tarn",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M141.825,226.025l-0.4,1.3l-2.5,1.6l-0.2,1.4l0.7,3.2l1.3,0.6l0.1,1.4l1.1,1.1l-0.3,2.6l7.1,4.7l0.5,1.4    l0.9,1.1l3.2,0.5l0.5-1.7l1.5-0.2l6,0.7l1.6-1l0.5-1.5l-0.4-4.9l3.1,1.1c1.1-0.7,2.2-1.4,3.4-2.2l-0.7-1.4l-5.5-0.6l-2.2-2.5    l-2.1-5.8l-2.4-2.1l-5.2-2.9l-3,1.4l-4.6,1.6l-1.4-0.6l-1.5,0.6L141.825,226.025z",
    },
    {
      id: "Z82",
      title: "Tarn-et-Garonne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M146.125,217.425h-0.1l-3.1,0.4l-3.7,2.8l-1.3-1.2l-4.4,1.3l-0.2-2l-1.2,0.7l-1.4-0.5l-2.3-2l1.1-1.1    l-1.6,0.2l-3.7,1v1.5l1.2,0.7l-2.2,2.7l0.7,1.4l-1.4-0.1l-1.8,2.4l2.7,0.8l-1.7,2.3l2.5,2l1,3.5l5-0.5l0.8,1.2l2.9-1.3l-1-1.1    l5.8-2.2l0.2-1.4l2.5-1.6l0.4-1.3l-0.9-1.1l1.5-0.6l1.4,0.6l4.6-1.6l-2.2-1.8l1.2-1.2l-1.5-1.4L146.125,217.425z",
    },
    {
      id: "Z83",
      title: "Var",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M247.125,232.025l-0.6,1.7l-2.9-1.8l-5.1,4.3l-2-2.4l-1.4,0.9l-2.6-0.1l-0.1,0.2l1,1.9l-1.6,0.4l-1,2.6    l2.7,4.6l-1.4,0.2l-0.5,1.3l1.1,3.2l-1.2,1l-0.2,1.6l3,1.8l-0.1,1.4l4.4-1.3l3,0.8l0.6,1.4l1-2.7l3.7,0.6v-1.4l2.8-0.7l1.4-1.2    l1.9,0.2l1.1-2.7l-1.8-0.9l3.1-4l2.7-0.4l0.9-1.7l-1-2l0.4-1.5l-2.8-1.2l-0.9-2.9l-2.7-1.4L247.125,232.025z",
    },
    {
      id: "Z84",
      title: "Vaucluse",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M223.725,221.825l-0.1-1.4l-4.7-0.4l-0.2-2.9l-1.3,1.1l-1.2-0.7l-4.3,1.9l-1.8-2.1l-2.9-0.3l0.1,1.8    l1.3,2.2v2.8l3.2,3.2l-2.2,2.5l5.2,1.4l2.7,2.9l2.2,1.1l1.8-0.3l6,2.4l1.6-0.1l3.3-2.2v0.1l0.1-0.2l-3-3l-1.8-0.5l0.9-1.3    l-1.9-2.6l0.6-1.5l-1-2.7L223.725,221.825z M214.225,213.725l-1.3,1.1l-0.6,1.5l1,1.1l2-0.6l1.2-1.7L214.225,213.725z",
    },
    {
      id: "Z85",
      title: "Vendée",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M79.625,132.425l-1.5,0.4l0.2,1.5l-1.2,1l-1.2-0.8v-1.6l-1.6,0.1l-0.2,4.8l-5.8-2.5l-3-3.1l-3.9,4.1    l0.2,2.2l5.2,6.1l1,3.6l4.1,3.3l2.4,0.6l0.8,1.7l2.9,0.6l2.4,1.9l0.3-1.4l1.5,0.3l4.4-1.7l-0.7,1.4l3.2-0.1l1.2,0.9l4.5-2.3    l-1.2-1l0.1-3.6l-2.4-9l-2.1-2.1l-1.1-2.8l-5.3-1.9l-2.4-1.8L79.625,132.425z",
    },
    {
      id: "Z86",
      title: "Vienne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M126.225,144.525l-1-1.5l-3.6-5l-0.1-1.6l-2.7-1.5l0.8,1.5l-5,0.2l-1.4-0.7l-0.7-3.1l-1.5,0.2l-0.1-1.6    l-2.7-1.6l-3.4,2.9l1.8,4.5l-0.5,1.5l1.4,0.6l-2.2,7.1l1.3-0.5l0.7,1.5l-1.7,2.8l0.3,2.9l1.1,1l-0.1,1.4l3.2,0.1l-1.6,4.2l2.3,1.5    l-0.5,1.5l4.6,1.5l1.5-0.5l-0.8-1.2l1.1-0.8l2.1,1.9l1.9-0.7l0.7-1.2l2.3,0.2v-1.7l1.5-2.5l2.9-1.4l0.6-1.5l1.7,0.5l0.5-1.2    l0.5,0.1l0.8-1.4l-1.3-0.6l-1.3-2.7h-1.4l-2.4-2.2L126.225,144.525z",
    },
    {
      id: "Z87",
      title: "Haute-Vienne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M119.325,174.725l3.1,1.5v2.4l4.8-0.6l3.5,2.8l-1,1.4l2.9,1.1l0.8-1.4l1.3,0.6l2.2-2l3-0.6l2.4-2.3l4.4-2    l-0.3-3l-2.5-1.5l-3.1-0.2l-0.1-1.4l-1.4-0.7l0.4-1.8l-0.8-2.6l-3.4-4.9l1.5-2.6l-0.6-1.3l-0.8-1.3l-2.9,0.6l-1.3-0.6l-0.5-0.1    l-0.5,1.2l-1.7-0.5l-0.6,1.5l-2.9,1.4l-1.5,2.5v1.7l-0.1,2.6l2.4,1.8l-0.3,1.6l-1.3,0.5l-2,4l-1.4,0.3L119.325,174.725z",
    },
    {
      id: "Z88",
      title: "Vosges",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M254.025,86.025L254.025,86.025l-0.8,0.2l-5.3,3.8l-3-0.8l-0.8-1.4l-2.6,1.9l-3,0.5l-1.2-0.9l-2.7,1    l-0.6,1.4l-2.9,0.4l-2-2.4v-1.5l-2.8-0.3l-1,1.2l-1.5-0.3l-3.5,1.7l-1.2,1.2l6.4,4.7l-0.8,4.3l2.7,2.3l0.1,1.6l1.3-0.5l0.7,1.2    v0.1l4-2.9l3.5,2.1l2.9-0.8l2.4,1.8l2.6-1.1l4.2,3.3l0.5-0.3l1.3-1.2l0.1-3.1l2.3-3.9l2.1-6.8l0.5-0.4l-2.5-1.1L254.025,86.025z",
    },
    {
      id: "Z89",
      title: "Yonne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M170.525,94.125l-0.1,2.9l-1.9,2.7l3.3,3.7l0.2,2.9l-2.3,2.5l0.6,1.4l-0.8,1.2l-2.7,1.7l1.5,1.4l1.2,3.4    v0.1h1.6l3.8,2.4h3l2.3-2.2l1.9,2.8l2.8,1.5l2.8-0.4l0.5,1.8l1.4-0.4l0.7,1.4l2.8-0.2l-0.6-1.4l0.7-4l3.3-5.2l-0.9-1.2l1.4-1    l0.2-1.9l-1.5-1.9l0.9-1.6l-1.5-0.7l-6.3,0.8l-2.5-5.4l-1.8-2.1l-1,1.1l-1.2-4.1l-2.2-2.8l-1.9-0.7L170.525,94.125z",
    },
    {
      id: "Z90",
      title: "Territoire de Belfort",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M249.625,107.525l-0.5,0.3l-1.2,2.9l1.3,4.9l3,1.3l-0.7,1.3l0.6,1.1l1.8-2h0.2v-0.1l1.9-0.2l-1-2.6    l-1.4,0.1l-0.2-4.5L249.625,107.525z",
    },
    {
      id: "Z91",
      title: "Essonne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M154.025,80.825l-0.1,0.1l-3.5,2.7l-0.8,1.3l0.3,1.4l-2.3,4.3l1.4,5.3h0.1l3.4-0.4l1-1.4l0.7,1.4l1.4-0.9    l1.9,0.3l1.7-2.4l1.9-8.6l-0.2-0.5l-1.1-1.1l-1.5,0.4l-2.5-1.1L154.025,80.825z",
    },
    {
      id: "Z92",
      title: "Hauts-de-Seine",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M264.9,18.7v-0.5l-1.2-1.3l-1.9,0.2l0,0l-0.8,1l-8.5,4.6l-1.2,1.4v2.4l-5.1,0.9v1.8l1.1,1l-0.3,2.4    l0.9,2.1l5.2,3.4v0.7l6.5,4.2l-1.2,1.4v1.2h2.5l1.2,1.7h0.7v-1.8l1.3-1.3v-7.9l1.5-1.5h-2.4l-2.3-2.2l-2.8-1.1v-0.9h-2.4l-1.1-0.9    l-0.1-1.1l1.6-2h2.4l3.8-2.8v-1.2v-1.4L264.9,18.7z M156.2,76.8l-0.6-1h-0.1l-1.7,1.3l-1,1.6l1.5,2.6l0.1-0.1l1.8,0.8l0.2-2l-1.5-1.8l1.3-0.8V76.8z",
    },
    {
      id: "Z93",
      title: "Seine-Saint-Denis",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M284.9,11.1l-1.6-0.8l-1.5,1.9l-1.2,0.3l-1.4,1.1l-2.2,3h-2.7l-1.4,0.7l-1.6-0.3l-2-2.7h-1.2l-1,1.6    l-1.6-0.2v-1.9l-0.6,0.3l-4.9,0.3l-0.3,1.5l2.1,1.2l0,0l1.9-0.2l1.2,1.3v0.5l-2.6,2.5v1.4h3.1l2.9,1.4l2.7,2.6v0.1l1.2,1.2    l4.5,2.3l1.6-2.1l1.1,1.1l3.5,2.7l3.5,3.7l-0.3-5.1l0.7-0.9v-1.4l-1-0.8l-0.2-3.1l1.3-2v-1.1l1.8-0.5l-1.8-2.6l0.3-1.2l-1.5-1.2    v-2.3l0.7-0.7v-1.6H284.9z M158.2,79l3.5,1.3v-0.1l-0.7-2.3l0.7-1.3l-0.8-2.5c-0.8,0.5-1.6,1.1-2.4,1.6l-1.3-0.5l-1.6,0.6l0.6,1v0.6    l1.6,0.2l0.3,1.4H158.2z",
    },
    {
      id: "Z94",
      title: "Val-de-Marne",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M279.4,29.2l-1.1-1.1l-1.6,2.1l-4.5-2.3l-1.2-1.2v1.9l-0.9,2.6l2.9,1.2l2.1,0.2v2.5l-0.7,0.3l-1.2-1.8    h-2.6l-0.3,0.8h-2.8v0.4h-1.9l-1.5,1.5v7.9l0.1-0.2l2,0.1l1,2.2l4.8,1.5h2.1l1.7-1.8l1-0.2l0.8,0.5l2-0.1l0.3,1l1.8,2.3l1.9,1.8    h1.3l1.3-1.8l1.7-1.3v-1l-1.2-0.8l0.2-1.7l0.9-1.2l1.1-0.3l0.7-1.3l-1.6-2.1v-1.6l-1.6-2.6l-3.5-3.7L279.4,29.2z M161.7,80.3l-3.5-1.3l0.8,0.9l-2.6,0.1l-0.2,2l2.5,1.1l1.5-0.4l1.1,1.1l0.5-2.1L161.7,80.3z",
    },
    {
      id: "Z95",
      title: "Val-d-Oise",
      coul: "#6699CC",
      url: "#",
      target: "_self",
      path: "M150.125,68.025l-3.2,0.9l-2.8-0.6l-0.8-1.6l-1.9,4.8l4.7,0.4l4.5,1.8l2.7,3h0.1l1.7-1.3h0.1l1.6-0.6    l1.3,0.5c0.8-0.5,1.6-1.1,2.4-1.6v-0.1l0.9-1.1l-0.2-1l-5.4-3.2l-1.1,0.8L150.125,68.025z",
    },
  ];

  const ourprojects = [
    {
      id: "5",
      nom: "Numérisation de cartes postales anciennes",
      typeprestations: "Numerisation",
      datedemande: "2023-10-14",
      deadline: "2023-11-01",
      budgetestimatif: "5000",
      datedebutprevisionnelle: "2023-11-23",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "2024-11-30",
      description:
        "Le présent projet concerne la numérisation de fonds iconographiques de la médiathèque de CA2BM.\r\nLe projet consiste à numériser des cartes postales datant principalement du début du XXème siècle, en niveaux de gris ou colorisées, en majorité de format A6.\r\n\r\nLe volume à numériser est : \r\nsoit 1000 cartes postales recto/verso, \r\nsoit 2000 cartes postales recto/verso. \r\n",
      statut: "prospect",
      responsable: "Hari",
      client: "CA2BM",
      interlocuteur: "FRICHOT Angèle",
      poste: "Directrice du Réseau de Lecture publique",
      mail: "a.frichot@ca2bm.fr",
      telephone: "a.frichot@ca2bm.fr ",
      ville: "Montreuil-sur-Mer",
      departement: "Z62",
      priorite: "6",
    },
    {
      id: "6",
      nom: "Catalogage et retroconversion 7000 fiches ",
      typeprestations: "Catalogage && Retroconversion",
      datedemande: "2023-10-03",
      deadline: "2023-11-01",
      budgetestimatif: "0",
      datedebutprevisionnelle: "2023-10-05",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Devis pour :\r\n•\tretroconversion des notices en fiches papier, petites formats, manuscrites et tapuscrites, installé dans un tiroir (voir photos en annexe): 3500\r\n•\tretroconversion des notices, à partir d'un inventaire tapuscrit papier (voir photo): 281 notices\r\n•\tcatalogage et retroconversion de trois fonds (fonds Seminaire, fonds XIX, fonds XX), livre à la main, pas de description, pas des cotes, notices à créer: 504+400+248 volumes\r\n•\tcatalogage et retroconversion  d'une collection (bibliothèque anglaise), livre à la main, cotes présentes, notices à créer : 3400 volumes.\r\nLes notices doivent être rédigé dans le format UNIMARC et des normes compatibles pour être versé dans notre SIGB (VSmart) tant que dans la Base Patrimoine. Nous n'envisageons pas faire des descriptions trop détaillée, vu que nos description sont plutôt succinctes.\r\nEn ce moment, il ne s'agit pas d'une demande de devis contraignante que je confie de vous adresser d'ici à la fin d'octobre/mi-novembre, mais d'une chiffre approximative pour pouvoir prévoir dans notre budget ce type d'opération pendant l'année 2024.\r\n",
      statut: "prospect",
      responsable: "Hari",
      client: "CAMBRAIS  Le Labo",
      interlocuteur: "Sara PRETTO ",
      poste:
        "Docteur en Histoire, Histoire de l'Art et Archéologie Cheffe du Service Collections Patrimoniales",
      mail: "spretto@media-cambrai.com",
      telephone: "",
      ville: "Cambrai",
      departement: "Z59",
      priorite: "2",
    },
    {
      id: "19",
      nom: "Numerisation inventaires musée",
      typeprestations: "Numerisation",
      datedemande: "2022-09-21",
      deadline: "2022-12-15",
      budgetestimatif: "4350",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "15/12/2022",
      datefinprevisionnelle: "",
      description:
        "Bonjour\r\nje me permets de vous contacter car nous souhaiterions procéder à la numérisation des registres des inventaires des collections des musées de Soissons.\r\nNous aurions donc besoin d'un devis afin d'estimer le coût de cette prestation",
      statut: "lost",
      responsable: "Hari",
      client: "VILLE DE SOISSONS ",
      interlocuteur: "Emilie RAMAN-BEIS",
      poste: "Assistante de direction des musées",
      mail: "e.raman-beis@ville-soissons.fr",
      telephone: "0323599160",
      ville: "PLACE DE L'HÔTEL DE VILLE 02200 SOISSONS",
      departement: "Z2",
      priorite: "0",
    },
    {
      id: "7",
      nom: "Inventaire photographique et retroconversion",
      typeprestations: "Retroconversion",
      datedemande: "2023-09-20",
      deadline: "2023-10-31",
      budgetestimatif: "0",
      datedebutprevisionnelle: "2023-10-27",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Objet du présent projet\r\nInventaire photographique et rétroconversion pour intégration des notices descriptives des ouvrages dans le Système Intégré de Gestion de Bibliothèque (SIGB) d’une bibliothèque privée.\r\n\r\nDescription de l’Existant\r\nLa bibliothèque privée située à Bagnères-de-Bigorre possède un patrimoine documentaire d'envergure, totalisant environ 13 500 volumes et occupant près de 190 mètres linéaires dans l’espace de stockage. La collection se compose principalement d'ouvrages publiés au cours du XXe siècle. Nombre de ces ouvrages sont déjà décrits dans le catalogue de la BnF. Une partie de la prestation  consistera à rechercher ces notices. (dérivations).\r\n\r\nRésumé du projet\r\n\r\nPour la première phase du projet, AureXus installe un atelier mobile pour les prises de vue sur site, à Bagnères-de-Bigorre. Cette phase devrait être achevée dans un délai de 7 semaines à compter de la commande. \r\nLe processus de rétroconversion débutera par une analyse du projet et des règles de catalogage et dérivation de la bibliothèque . AureXus tentera de récupérer les notices correspondantes depuis la BnF dans la mesure du possible. Une tentative de dérivation est réalisée pour toutes les notices. On attend 85% de dérivations. Les notices qui ne peuvent pas être dérivées,  soit 15%, seront créées. \r\n",
      statut: "prospect",
      responsable: "Hari",
      client: "Bibliothèque privée",
      interlocuteur: "François GIUSTINIANI",
      poste:
        "Conservateur général du patrimoine Conservator generau deth patrimòni Directeur des Archives et des patrimoines Director deths Archius e deths patrimònis Direction du Développement local",
      mail: "francois.giustiniani@ha-py.fr",
      telephone: "05 62 56 76 19",
      ville: "Bagnères-de-Bigorre",
      departement: "Z65",
      priorite: "8",
    },
    {
      id: "8",
      nom: "Numérisation de fonds patrimonial (X30 LIVRES)  9000 pages",
      typeprestations: "Numerisation",
      datedemande: "2023-09-05",
      deadline: "2023-11-02",
      budgetestimatif: "3000",
      datedebutprevisionnelle: "2024-01-02",
      dureeprevisionnelle: "15 jours",
      datefinprevisionnelle: "",
      description:
        "Super projet à venir. Numérisation de fonds patrimonial (X30 LIVRES)  9000 pages.\r\nIl s'agit de numériser et d'Océriser 9000 pages de livres de livres dont les échantillons d'imagesnous ont déjà été parvenues.",
      statut: "prospect",
      responsable: "Hari",
      client: "Médiathèque de Pont-à-Mousson",
      interlocuteur: "Laurence Fruchard",
      poste: "Directrice adjointe - Réseau des médiathèques",
      mail: "laurence.fruchard@bassin-pont-a-mousson.fr",
      telephone: " 03 83 84 09 09",
      ville: "Pont-à-Mousson",
      departement: "Z54",
      priorite: "2",
    },
    {
      id: "9",
      nom: "Transcription d’inventaires manuscrits",
      typeprestations: "Saisie",
      datedemande: "2023-08-22",
      deadline: "2023-10-05",
      budgetestimatif: "20000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "2024-02-01",
      description:
        'Objet du présent projet\r\nTranscription d’inventaires manuscrits des Collections photographiques de la Commission du Vieux Paris.\r\n\r\n\r\nDescription de l’Existant\r\nLes Archives de la Commission du Vieux Paris possèdent un ensemble de 23 manuscrits. Ces manuscrits constituent l\'inventaire d\'une partie des collections photographiques de la Commission du Vieux Paris (CVP) pour les films souples des années 1963 à 1997.\r\nLes premiers 17 manuscrits présentent les informations suivantes : cote du film, complément de cote pour une prise de vue, correspondance avec une cote de vue de projection, nombre de négatifs, format du négatif, lieu/objet, observations.\r\nLes volumes intitulés "Demandes en autorisation de démolir" et "Démolitions" (couvrant 1974-1997) complètent les données des autres manuscrits avec des adresses photographiées, numéros de liste, dates de prise de vue, liens avec des "numéros de vue de projection", et objets liés à des demandes d\'urbanisme.\r\n',
      statut: "prospect",
      responsable: "Hari",
      client: "Ville de Paris - Commission du Vieux Paris",
      interlocuteur: "Pauline Rossi",
      poste: "",
      mail: "pauline.rossi@paris.fr",
      telephone: "33 01.71.28.20.30",
      ville: "Paris",
      departement: "Z75",
      priorite: "2",
    },
    {
      id: "10",
      nom: "Signalement rétrospectif de collections",
      typeprestations: "Catalogage",
      datedemande: "2023-07-24",
      deadline: "2023-10-10",
      budgetestimatif: "15000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "3 mois",
      datefinprevisionnelle: "",
      description:
        "Description de l’existant\r\nNotices actuelles\r\nLes notices actuelles sont variées, allant d'ouvrages anciens datant du milieu du 19e siècle au début du 20e siècle, ainsi que quelques ouvrages plus récents des années 1950-1960. \r\n\r\nVolume\r\nLe volume à traiter est de 6 000 notices, avec les particularités suivantes :\r\n3 000 notices sont déjà saisies et manquent de données de date, empêchant leur intégration dans le Sudoc.\r\n3 000 autres notices présentent le titre et auteur dans une même zone, nécessitant une séparation pour se conformer aux standards du Sudoc.\r\n\r\n\r\nPrestations à réaliser\r\nLes prestations à réaliser dans le cadre de ce projet incluent :\r\nRévision des notices existantes pour ajouter les dates manquantes, permettant leur intégration au réseau Sudoc.\r\nCorrection et séparation des informations titre/auteur dans les notices concernées pour répondre aux spécifications du Sudoc.\r\nRéalisation de l'indexation Rameau. (optionnel) \r\n",
      statut: "lost",
      responsable: "Hari",
      client: "MPP Charenton",
      interlocuteur: "Ludivine Javelaud ",
      poste:
        "Responsable de la bibliothèque Département des archives et de la bibliothèque",
      mail: "ludivine.javelaud@culture.gouv.fr",
      telephone: "33 06 37 33 83 44",
      ville: "Charenton",
      departement: "Z94",
      priorite: "9",
    },
    {
      id: "12",
      nom: "Prestation OCR",
      typeprestations: "OCR",
      datedemande: "2023-09-07",
      deadline: "0000-00-00",
      budgetestimatif: "10000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Dans le cadre d'un projet d'expérimentation du Cned, nous allons avoir besoin d'utiliser des techniques d'OCR afin d'interpréter nos fichiers. Nous avons à notre disposition environ 100 000 documents déjà numérisés, certains manuscrits, d'autres non. Je souhaiterais connaitre votre positionnement concernant ce type de prestation, notamment un exemple de votre champ de compétences en terme d'océrisation de documents manuscrits, ainsi que vos tarifs et engagements sécurité & RGPD (notamment en termes de prestataires).",
      statut: "left",
      responsable: "Hari",
      client: "CNED",
      interlocuteur: "Florent VENTURA",
      poste: "",
      mail: "florent.ventura@ac-cned.fr",
      telephone: "0617351989",
      ville: "Mont-Saint-Aignan",
      departement: "Z76",
      priorite: "3",
    },
    {
      id: "14",
      nom: "Inventaire de collections",
      typeprestations: "Inventaire",
      datedemande: "2023-09-04",
      deadline: "0000-00-00",
      budgetestimatif: "0",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "La Communauté d'agglomération Roissy Pays de France accompagne la commune de Vémars (Val-d'Oise) dans la valorisation de la mémoire de François Mauriac, prix Nobel de littérature et académicien, qui a résidé au château de la Motte. La Ville de Vémars consacre un petit musée à l'écrivain dans cette \"maison François Mauriac\" qui abrite aujourd'hui la mairie et une médiathèque. Y sont conservées des collections liées à François Mauriac, qu'il lui faut inventorier, et qui se composent de : \r\n- ouvrages ayant appartenu à François Mauriac et constituant l'essentiel des collections\r\n- mobilier, effets personnels (chapeau, couverture...) et objets divers (masque mortuaire)\r\n- archives (invitations, papiers manuscrits...)\r\n- photographies\r\n",
      statut: "left",
      responsable: "",
      client: "Roissy Pays de France",
      interlocuteur: "Mélanie L'Hénoret",
      poste: "",
      mail: "mlhenoret@roissypaysdefrance.fr",
      telephone: "0134721817",
      ville: "Roissy Pays de France",
      departement: "Z95",
      priorite: "3",
    },
    {
      id: "15",
      nom: "Pack de base application supportant jusqu'à 10 capteurs par pack",
      typeprestations: "POLIPIX",
      datedemande: "2023-08-19",
      deadline: "2023-09-10",
      budgetestimatif: "8000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "La médiathèque Luxembourg (communauté d'agglomération du Pays de Meaux) souhaite s’équiper d’une solution pour améliorer la conservation préventive de ses collections, tout en pouvant surveiller en une unique interface les variations de l’hygrométrie et de suivre les fuites d’eau. \r\n\r\nLes capteurs d’humidité, de température, de luminosité et de dégâts d’eau proposés permetteront d’optimiser les conditions de stockage.\r\nLa surface totale du site à couvrir est de 420 m2, comprenant un bâtiment principal de 293 m2, un magasin secondaire de 112 m2 et une autre petite pièce de 15 m2. La connectivité dans les deux premiers magasins reste faible voir inexistante. \r\n",
      statut: "prospect",
      responsable: "",
      client: "Mediatheque du Luxembourg",
      interlocuteur: "Durand-Viel Marion",
      poste: "Responsable du pôle Patrimoine ,Direction de la Culture ",
      mail: "Marion.Durand-Viel@meaux.fr",
      telephone: "0183690124",
      ville: "MEAUX",
      departement: "Z77",
      priorite: "8",
    },
    {
      id: "16",
      nom: "SAINTES  MARIES  Médiatheques musee",
      typeprestations: "POLIPIX",
      datedemande: "2023-10-09",
      deadline: "0000-00-00",
      budgetestimatif: "7557",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "la mise en place de 10 capteurs dans le musée avec suivi de la température et de l’hygrométrie via la plateforme en ligne.\r\nVous trouverez ci-joint les plans localisant les capteurs dans le musée (RDC et R+1).",
      statut: "prospect",
      responsable: "Hari",
      client: "Mairie des Saintes-Maries-de-la-Mer",
      interlocuteur: "Service musée",
      poste: "",
      mail: "accueil-museal@lessaintesmaries.fr",
      telephone: "",
      ville: "6 avenue Théodore Aubanel, 13460 SAINTES-MARIES-DE-LA-MER",
      departement: "Z13",
      priorite: "8",
    },
    {
      id: "17",
      nom: "Indexation périos santé",
      typeprestations: "Indexation",
      datedemande: "2023-07-19",
      deadline: "2023-10-16",
      budgetestimatif: "25000",
      datedebutprevisionnelle: "2023-10-02",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "L’enrichissement des notices bibliographiques par une indexation matière précise, de type Rameau\r\nou F-MeSH, permet de distinguer, au sein d’un corpus général (PCMed par exemple), certaines\r\nsous-thématiques précises (gynécologie, gériatrie, médecine tropicale...).\r\nLes thésaurus de référence pour ce projet sont le MeSH (anglophone), le F-MeSH (déclinaison\r\nfrancophone) et Rameau. Le MeSH n'est pas mobilisé directement, le F-MeSH est utilisé à l'aide\r\nd'un guide. Rameau n'est pas utilisé directement.\r\nLe projet s'opère en trois temps. La prestation demandée concerne la phase 2.\r\n1. Élaboration d'un guide d'indexation en F-MeSH qui en retient une version allégée (<200\r\npoints d'accès). ACHEVÉ\r\n2. Indexation des titres\r\n1. en F-MeSH avec le guide d'indexation\r\n2. en langage naturel, si le guide d'indexation ne suffit pas\r\n3. Alignement des termes en langage naturel avec Rameau\r\nUne fois ces opérations achevées, versement automatisé des données dans le SUDOC.",
      statut: "left",
      responsable: "Hari",
      client: "BIUS Médecine — Pôle Santé Département Collections",
      interlocuteur: "Thibault de Prémorel",
      poste: "Responsable du circuit et traitement du document",
      mail: "thibault.de-durand-de-premorel@parisdescartes.fr",
      telephone: "01 76 53 18 96",
      ville: "12 rue de l'École de médecine | Paris 6e",
      departement: "Z75",
      priorite: "6",
    },
    {
      id: "18",
      nom: "Rétroconversion de fonds patrimoniaux",
      typeprestations:
        "Rétroconversion de notices pour les fonds patrimoniaux non signalés en UNIMARC.",
      datedemande: "2023-06-17",
      deadline: "0000-00-00",
      budgetestimatif: "21000",
      datedebutprevisionnelle: "2024-04-24",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "La Bibliothèque d'Épernay souhaite relancer et finaliser la rétroconversion de certains de ses fonds patrimoniaux. Ayant déjà collaboré avec AureXus en 2019 pour un projet similaire, la bibliothèque sollicite à nouveau l’expertise de cette dernière pour mener à bien cette opération. \r\nLa démarche s'inscrit dans la perspective de solliciter le concours particulier \"Bibliothèques\" de la dotation générale de décentralisation (DGD) et de présenter une candidature d'ici le mois de novembre 2023. Les fonds concernés sont les cotations AF (ancien fonds) des XVIIIe et XIXe siècles, ainsi que les fonds cotés Réserve des XVIIe, XVIIIe et XIXe siècles.\r\nPour chaque ensemble de fonds, la Bibliothèque d'Épernay possède un inventaire papier manuscrit détaillé, constituant une ressource pour la rétroconversion. Les ouvrages de ces fonds datent principalement des XVIIe, XVIIIe et XIXe siècles, et les langues présentes sont le français et le latin. Aucun caractère non latin n'est identifié dans les fonds.",
      statut: "prospect",
      responsable: "Hari",
      client: "Médiathèque Simone-Veil  EPERNAY ",
      interlocuteur: "Mongin Frédéric",
      poste:
        "Directeur Adjoint des Médiathèques Services Opérationnels - Affaires culturelles",
      mail: "frederic.mongin@ville-epernay.fr",
      telephone: "03 26 53 37 86",
      ville:
        "Site : Médiathèque Simone-Veil - 1 rue du Professeur Langevin Adresse postale : Ville d'Épernay - B.P. 505 - 51331 EPERNAY CEDEX",
      departement: "Z51",
      priorite: "9",
    },
    {
      id: "20",
      nom: "Indexation de la Revue générale de clinique et de thérapeutique",
      typeprestations: "Indexation",
      datedemande: "2022-10-19",
      deadline: "2022-11-08",
      budgetestimatif: "30000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Résumé point par point :\r\n\r\n1. La BIU Santé (Université Paris Cité) a numérisé tous les volumes d'un périodique.\r\n2. L'objectif est d'indexer le contenu de ces volumes avant de les mettre en ligne sur la bibliothèque numérique Medica.\r\n3. Le volume total à indexer comprend 118,528 images réparties dans 103 dossiers.\r\n4. Un fichier Excel contenant la liste des identifiants de ces dossiers et le nombre d'images est inclus dans le cahier des charges.\r\n5. Un devis était attendu au plus tard le 8 novembre.\r\n6. Des exemples de volumes sont regroupés dans un dossier téléchargeable au format zip, accessible via un lien fourni.\r\n7. Un exemple de fascicule, avec une table alphabétique des matières, est également disponible via un lien fourni.\r\n8. Les premières années comportent plusieurs fascicules par dossier d'images, chaque fascicule contenant en moyenne 16 pages (images).\r\n9. À partir de 1909, il y a deux dossiers d'images par an (sauf pour 4 cotes) : un bloc avec le corps principal (plusieurs fascicules à la suite, de 16 pages en moyenne) et un bloc d'annexes.\r\n10. En ce qui concerne le budget, des offres autour de 0,10-0,15€ par page sont habituellement retenues, mais cela pourrait varier en fonction d'un accord avec le service financier.",
      statut: "lost",
      responsable: "",
      client: "BIU Santé Médecine – Pôle Santé",
      interlocuteur: "Morgane Rousselot",
      poste:
        "Chargée de la bibliothèque numérique - Département d’histoire de la santé",
      mail: "morgane.rousselot@parisdescartes.fr",
      telephone: " 01 76 53 18 58",
      ville: "12 rue de l’École de médecine 75270 Paris Cedex 06 ",
      departement: "Z75",
      priorite: "0",
    },
    {
      id: "21",
      nom: "Numérisation d’archives et d’imprimés",
      typeprestations: "Numerisation",
      datedemande: "2022-11-21",
      deadline: "0000-00-00",
      budgetestimatif: "13000",
      datedebutprevisionnelle: "2023-05-02",
      dureeprevisionnelle: "2 mois",
      datefinprevisionnelle: "",
      description:
        "Numérisation d’archives et d’imprimés des Archives Départementales de la Vendée. Les documents originaux datent des XVIIIe, XIXe et XXe siècles.\r\nLes documents à traiter sont répartis en 2 lots :  \r\nLot 1 : Archives manuscrites : 18 622 vues au total dont\r\nRegistres d’hypothèque (4Q, 1num186) : 13 registres reliés, format A2 (2 619 vues)\r\nRegistres de l'Évêché de Luçon (1num404) : 8 volumes reliés, format A2 (2 346 vues)\r\n=> soit un total de 4 965 vues en format A2\r\nMinutes notariales (3E) : 29 boites document format A4 (11 400 vues, 29 cotes)\r\nRegistres de la baronnie de Bouin (8B, 2num423) : 21 registres reliés format A2 ou A3 \r\n(2 257 vues)\r\nLot 2 : Imprimés (2 501 vues) : \r\nCartulaires et autres imprimés : 10 volumes, format A4 ou A5 (2501 vues)\r\n",
      statut: "lost",
      responsable: "Hari",
      client: "Archives Départementales de la Vendée",
      interlocuteur: "Juliette Bourcereau",
      poste: "",
      mail: "archives@vendee.fr",
      telephone: " 02 28 85 78 00",
      ville: "14, rue Haxo BP 34 85 000 La Roche-sur-Yon cedex",
      departement: "Z85",
      priorite: "0",
    },
    {
      id: "22",
      nom: "Signalement d'ouvrages patrimoniaux dans le SUDOC",
      typeprestations: "Signalement",
      datedemande: "2023-02-01",
      deadline: "0000-00-00",
      budgetestimatif: "0",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Bonjour,\r\nJe souhaiterais recevoir un devis pour signalement d'ouvrages considérés comme patrimoniaux (du XVI au XIX-XXe siècles), avec les spécificités suivantes :\r\n- coût à la notice HT/TTC\r\n- estimation du nombre de notices / heure\r\n- signalement dans le SUDOC (majoritairement : rattachement à une notice déjà présente et localisation OU pour quelques cas création de la notice) + Rameau // option : coût sans Rameau\r\n\r\nLes ouvrages ne présentent pas de spécificités marquées ni difficultés de langues (fr, latin, éventuellement un peu de grec).\r\nIls sont soit déjà présents et localisés dans le SUDOC (mais mal catalogués) [cas très majoritaire] soit non informatisés [minoritaire].\r\n\r\nPour un travail livre en main, nos collègues de la bibliothèque municipale ont signalé le travail de Mme Rachel Joly (joly.rachel@gmx.fr // 0643085702), disponible sur Dijon, et ayant déjà travaillé dans le cadre de ce type de prestation.\r\nPour un travail sur photocopies (page de titres, éventuellement colophon), je vous remercie de me préciser la procédure que vous suivez.\r\n",
      statut: "lost",
      responsable: "Hari",
      client: "Bibliothèque universitaire (Dijon)",
      interlocuteur: "Rodolphe Leroy",
      poste: "",
      mail: "rodolphe.leroy01@u-bourgogne.fr",
      telephone: "",
      ville: "Dijon",
      departement: "Z21",
      priorite: "0",
    },
    {
      id: "23",
      nom: "Catalogage, indexation de livres anciens",
      typeprestations: "Catalogage && Indexation",
      datedemande: "2022-09-28",
      deadline: "0000-00-00",
      budgetestimatif: "6600",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Prises de vue des pages titres à la BU Lyon1,\r\nCréation des notices à Angers (atelier AureXus), \r\nTraitement des anomalies (environ 5% du volume total) sur la plateforme Medusa, \r\nChargement en continue des notices dans le SUDOC \r\n",
      statut: "lost",
      responsable: "",
      client: "Université Claude Bernard Lyon 1",
      interlocuteur: "Rapatel Livia",
      poste: "",
      mail: "livia.rapatel@univ-lyon1.fr",
      telephone: "04 72 43 29 76",
      ville:
        "Domaine de la Doua \r\n20 avenue Gaston Berger\r\n69622 Villeurbanne CEDEX",
      departement: "Z69",
      priorite: "0",
    },
    {
      id: "24",
      nom: "Numérisation d'archives - GEA",
      typeprestations: "Numerisation",
      datedemande: "2023-03-16",
      deadline: "0000-00-00",
      budgetestimatif: "810000",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "12 à 24 mois",
      datefinprevisionnelle: "",
      description:
        "Voici en pièce jointe les photos du stockage de nos archives, pour l’instant nous allons trier que celle se situant en bas.\r\nLes archives se trouvent à Montigny le Bretonneux dans les Yvelines.\r\nIl y a 9 linéaires de 6 étagères de 7 mètres avec des boites d’archives de différentes épaisseurs, rempli de feuilles ou de plans et il y a aussi des classeurs.\r\nIl peut y avoir dans une boite de 200 à 400 feuilles A4 (avec ou non des agrafes) et une autre avec 25 plans AO.\r\nDans chaque boite le contenu peut être différent.\r\nVous pourrez tout prendre afin de numériser directement dans vos locaux.\r\nL’état des feuilles est bon, pour les avoirs manipulé, elles ne sont pas fragiles.\r\nTout ce qui est question du type de numérisation, je ne pourrais à ce jour pas être assez précis.\r\nNous souhaitons que ce soit classé numériquement comme ce l’était dans les boites avec un logiciel simple afin de tout retrouver.\r\nNous pourrons stocker par la suite les données chez nous dans un serveur.\r\n",
      statut: "lost",
      responsable: "Hari",
      client: "GEA Region WEMEA",
      interlocuteur: "Julien GONARD",
      poste: "Chargé d'Affaires Techniques QHSE France",
      mail: "julien.gonard@gea.com",
      telephone: "78180",
      ville: "Yvelines",
      departement: "Z78",
      priorite: "0",
    },
    {
      id: "25",
      nom: "Proposition de déploiement de  POLIPIX ",
      typeprestations: "POLIPIX ",
      datedemande: "0000-00-00",
      deadline: "0000-00-00",
      budgetestimatif: "8791",
      datedebutprevisionnelle: "",
      dureeprevisionnelle: "",
      datefinprevisionnelle: "",
      description:
        "Le Musée de la Nacre et de la Tabletterie souhaite s’équiper d’une solution pour améliorer la conservation préventive de ses collections, tout en pouvant surveiller en une unique interface l’intégralité des conditions de stockage. Le musée a décidé d'utiliser initialement  10 capteurs pour surveiller de manière automatique les niveaux de température, humidité et d’intensité d’éclairage dans les espaces de stockage et salles d’exposition du musée.",
      statut: "inprogress",
      responsable: "",
      client: "Musée de la Nacre et de la Tabletterie",
      interlocuteur: "Clémence GOUVINE",
      poste: "Régisseur des oeuvres",
      mail: "cgouvine@musee-nacre.fr",
      telephone: "0344226174",
      ville:
        "Musée de la Nacre et de la Tabletterie 51, rue Roger Salengro 60110 Méru",
      departement: "Z60",
      priorite: "0",
    },
  ];

  /* ----------------------------------------------------
                         Little projects list modal
    ----------------------------------------------------- */
  const [actualzone, setActualzone] = useState(null);
  const [blockX, setBlockX] = useState(null);
  const [blockY, setBlockY] = useState(null);
  const [iconpath, setIconpath] = useState(
    // "M256,0c-85.7,0-155.2,68.2-155.2,152.2C100.8,236.3,256,512,256,512s155.2-275.7,155.2-359.8C411.2,68.2,341.7,0,256,0z"
    "M 256 0 c -85.7 0 -155.2 68.2 -155.2 152.2 C 100.8 236.3 256 512 256 512 s 155.2 -275.7 155.2 -359.8 C 411.2 68.2 341.7 0 256 0 z"
  );
  const [iconpath02, setIconpath02] = useState(
    "M15,16.469c-2.856,0-5.172,2.272-5.172,5.072c0,2.803,5.172,11.99,5.172,11.99s5.172-9.188,5.172-11.99 C20.172,18.742,17.856,16.469,15,16.469z"
  );

  const zonework = (e, id) => {
    const projectslist = ourprojects
      .filter((project) => project.departement === id)
      .map((projectname) => (
        <div>
          <div key={projectname.id} className="myprojectdiv">
            {projectname.client}
          </div>
          <li>{projectname.nom}</li>
        </div>
      ));
    setActualzone(projectslist);
    setBlockX(e.pageX);
    setBlockY(e.pageY);
    // console.log(e.pageX, e.pageY);
  };

  const [mapPinIcon01X, setMapPinIcon01X] = useState("");
  const [mapPinIcon01Y, setMapPinIcon01Y] = useState("");
  const seticonpos = (e) => {
    const svgRect = e.target.getBoundingClientRect();
    const svgRect2 = e.currentTarget.getBoundingClientRect();
    console.log(svgRect2);
  };

  /* ----------------------------------------------------
                         Toggle Missions/Devis
    ----------------------------------------------------- */
  const [devis, setDevis] = useState(false);
  const [showmodal02, setShowmodal02] = useState(false);

  // const closemodal02 = () => {
  //   setShowmodal02(false);
  // }

  /* Fiche mission Modal */
  const [fichemission, setFichemission] = useState(false);

  const id = useSelector((state) => state.idReducer.id);

  const todos = useSelector((state) => state.todoReducer.todos);

  // console.log(comments);

  const [currentDateTime, setCurrentDateTime] = useState("");
  useEffect(() => {
    // Fonction pour obtenir la date et l'heure actuelles
    const getCurrentDateTime = () => {
      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString(); // Format ISO pour inclure la date et l'heure
      setCurrentDateTime(formattedDateTime);
    };

    // Appeler la fonction pour la première fois
    getCurrentDateTime();

    // Mettre en place un intervalle pour mettre à jour la date et l'heure périodiquement (par exemple, toutes les secondes)
    const intervalId = setInterval(getCurrentDateTime, 1000);

    // Nettoyer l'intervalle lorsqu'un composant est démonté
    return () => clearInterval(intervalId);
  }, []); // Le tableau vide signifie que cet effet n'a lieu qu'une fois après le montage initial

  /* ----------------------------------------------------
                        Missions
  ----------------------------------------------------- */
  const closefile = () => {
    setShowmodal02(false);
    dispatch(getFiche(false));
    prioform.current.reset();
  };

  const [missionid, setMissionid] = useState(Number(40));

  const mission =
    !isEmpty(missions) &&
    missions.find((mission) => Number(mission.id) === Number(missionid));
  // console.log(mission);

  const projectprio = useSelector((state) => state.prioReducer.prio);
  const projectstatus = useSelector((state) => state.statusReducer.statut);

  // -----------------------------------------------------//
  //         Priorité et statut de la mission             //
  // -----------------------------------------------------//

  // ---------------------------------------------------------------------- A changer
  // ---------------------- Section Priority
  const prioform = useRef();
  const setpriority = () => {
    const data = {
      id: prioform.current[0].value,
      priorite: prioform.current[1].value,
    };
    dispatch(editMissionprio(data));
  };

  // ---------------------------------------------------------------------- A changer
  // ---------------------- Section Status du projet
  const stateform = useRef();
  const setstatus = () => {
    const data = {
      id: stateform.current[0].value,
      statut: stateform.current[1].value,
    };
    dispatch(setMissionstatus(data));
  };

  // ---------------------- Section TodoList
  const form = useRef();
  const handleform = async (e) => {
    e.preventDefault();

    const postdata = {
      project: form.current[0].value,
      mission: form.current[1].value,
      todo: form.current[2].value,
    };
    dispatch(postTodo(postdata));
    form.current.reset();
  };

  const checkme = (id) => {
    const currentTodo = todos.find((todo) => todo.id == id);

    if (currentTodo) {
      const modifydata = {
        id: id,
        checked: currentTodo.checked ? 0 : 1, // Inverse la valeur actuelle
      };
      dispatch(editTodo(modifydata));
    } else {
      console.error("ID non défini, impossible de mettre à jour.");
    }
  };

  const deleteme = (id) => {
    if (id) {
      dispatch(deleteTodo(id));
    } else {
      console.error("ID non défini, impossible de mettre à jour.");
    }
  };

  // ---------------------- Section links
  const links = useSelector((state) => state.linkReducer.links);
  const mylink = useRef();

  const linkform = (e) => {
    e.preventDefault();
    const data = {
      filename: mylink.current[0].value,
      filelink: mylink.current[1].value,
      projectid: mylink.current[2].value,
      missionid: mylink.current[3].value,
    };
    // alert(link.current[1].value);
    dispatch(postLink(data));
    mylink.current.reset();
  };
  const deletelink = (id) => {
    if (id) {
      dispatch(deleteLink(id));
    } else {
      console.error("ID non défini, impossible de mettre à jour.");
    }
  };
  const commentaire = useRef();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const commentform = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const data = {
      datecomment: commentaire.current[0].value,
      pidforcomment: commentaire.current[1].value,
      midforcomment: commentaire.current[2].value,
      commentaire: JSON.stringify(rawContentState), // Convert editor content to JSON
    };
    dispatch(postComment(data));
    setEditorState(EditorState.createEmpty()); // Reset editor state
  };
  const deletecomment = (id) => {
    if (id) {
      dispatch(deleteComment(id));
    } else {
      console.error("ID non défini, impossible de mettre à jour.");
    }
  };

  /* ----------------------------------------------------
              Toggle status (Devis ou Missions)
    ----------------------------------------------------- */
  const devisbutton = () => {
    setSelectedstatus("prospect");
    setDevis(true);
  };

  const missionbutton = () => {
    setSelectedstatus("inprogress");
    setDevis(false);
  };

  /* ----------------------------------------------------
     ----------------------------------------------------
     ----------------------------------------------------
     ----------------------------------------------------
     |                        JSX                       |
     ----------------------------------------------------
     ----------------------------------------------------
    ----------------------------------------------------- */
  return (
    <div id="mainpage">
      <div id="mainpagecontainer">
        <div id="allcontainer">
          <div id="principalcontainer">
            <div
              className={
                lastprojectmodalbis
                  ? "lastprojectmodalbis"
                  : "lastprojectsmodal"
              }
              id="lastprojectsmodal"
              onClick={() => setLastprojectmodalbis(false)}
            >
              <div className="lastprojects">
                <h3>Les derniers projets mis à jour</h3>

                <ul className="selectlist">
                  {!isEmpty(projects) &&
                    comments && // Check if comments is not null
                    projects
                      .filter((project) => project.statut === selectedstatus)
                      .sort((a, b) => {
                        // Find the latest comment for each project
                        const latestCommentA = comments
                          .filter((comment) => comment.pidforcomment === a.id)
                          .sort((x, y) =>
                            y.datecomment.localeCompare(x.datecomment)
                          )[0];
                        const latestCommentB = comments
                          .filter((comment) => comment.pidforcomment === b.id)
                          .sort((x, y) =>
                            y.datecomment.localeCompare(x.datecomment)
                          )[0];

                        // Handle cases where there might not be any comments
                        if (!latestCommentA) return 1;
                        if (!latestCommentB) return -1;

                        // Compare projects based on the date of the latest comment
                        return latestCommentB.datecomment.localeCompare(
                          latestCommentA.datecomment
                        );
                      })
                      .map((project) => {
                        // Find the latest comment for the current project
                        const latestComment = comments
                          .filter(
                            (comment) => comment.pidforcomment === project.id
                          )
                          .sort((x, y) =>
                            y.datecomment.localeCompare(x.datecomment)
                          )[0];

                        return (
                          <li key={project.id}>
                            <a onClick={() => selectedproject(project.id)}>
                              {project.nom} -{" "}
                              {latestComment
                                ? latestComment.datecomment
                                : "No comments"}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                <div>
                  <button onClick={() => setLastprojectmodalbis(false)}>
                    Fermer
                  </button>
                </div>
              </div>
            </div>

            {showpricelist == false ? (
              ""
            ) : (
              <div
                id="listedeprix"
                // className={ showallprices ? "lastprojectmodalbis" : "lastprojectsmodal"
                className={"lastprojectmodalbis"}
              >
                <div className="lastprojects">
                  <h3>Liste de prix</h3>
                  <div className="selectlist03">
                    <table>
                      {/* <thead>
                      <tr>
                        <td>Prestation</td>
                        <td>Item</td>
                        <td>Description</td>
                        <td>Prix</td>
                        <td>Date</td>
                      </tr>
                    </thead> */}
                      {!isEmpty(prices) &&
                        prices
                          // .filter((price) => {
                          //   const matchingprestations = prestations.filter(
                          //     (prestation) =>
                          //       mysearch
                          //         ? removeAccents(
                          //             prestation.typeprestations.toLowerCase()
                          //           ).includes(
                          //             removeAccents(mysearch.toLowerCase())
                          //           ) && price.prestation == prestation.id
                          //         : true
                          //   );

                          //   const matchingitems =
                          //     !isEmpty(items) &&
                          //     items.filter((item) =>
                          //       mysearch
                          //         ? removeAccents(
                          //             item.item.toLowerCase()
                          //           ).includes(
                          //             removeAccents(mysearch.toLowerCase())
                          //           ) && price.item == item.id
                          //         : true
                          //     );
                          //   return (
                          //     matchingprestations.length > 0 ||
                          //     matchingitems.length > 0
                          //   );
                          // })
                          // .filter((price) => {
                          //   const matchingProjects = projects.filter(
                          //     (project) =>
                          //       project.id === price.projectid &&
                          //       project.statut === selectedstatus
                          //   );
                          //   return matchingProjects.length > 0; // Vérifier s'il y a des projets correspondants
                          // })
                          .map((price) => (
                            <tr key={price.id}>
                              <a
                                onClick={() => selectedproject(price.projectid)}
                                className="gridme"
                              >
                                <td className="price02">
                                  {(() => {
                                    const matchingPrestation = prestations.find(
                                      (prestation) =>
                                        prestation.id === price.prestation
                                    );

                                    return (
                                      <span key={price.id}>
                                        {matchingPrestation &&
                                          matchingPrestation.typeprestations}
                                      </span>
                                    );
                                  })()}
                                </td>

                                <td className="price03">
                                  {(() => {
                                    const matchingPrestation =
                                      !isEmpty(items) &&
                                      items.find(
                                        (item) => item.id === price.item
                                      );

                                    return (
                                      <span key={price.id}>
                                        {matchingPrestation &&
                                          matchingPrestation.item}
                                      </span>
                                    );
                                  })()}
                                </td>

                                <td className="price04">
                                  {(() => {
                                    const matchingPrestation =
                                      !isEmpty(descriptions) &&
                                      descriptions.find(
                                        (description) =>
                                          description.id === price.description
                                      );

                                    return (
                                      <span key={price.id}>
                                        {matchingPrestation &&
                                          matchingPrestation.description}
                                      </span>
                                    );
                                  })()}
                                </td>

                                <td className="price05">
                                  {price.price} € / {price.unity}
                                </td>

                                <td className="price06">
                                  {(() => {
                                    const matchingPrestation =
                                      !isEmpty(projects) &&
                                      projects.find(
                                        (project) =>
                                          project.id === price.projectid
                                      );

                                    return (
                                      <span key={price.id}>
                                        {matchingPrestation &&
                                          matchingPrestation.client}
                                      </span>
                                    );
                                  })()}
                                </td>

                                <td className="price08">
                                  {(() => {
                                    const matchingPrestation =
                                      !isEmpty(projects) &&
                                      projects.find(
                                        (project) =>
                                          project.id === price.projectid
                                      );

                                    return (
                                      <span key={price.id}>
                                        {matchingPrestation &&
                                          matchingPrestation.statut}
                                      </span>
                                    );
                                  })()}
                                </td>

                                <td className="price01">{price.date}</td>

                                <td className="price07">{price.comment}</td>
                              </a>
                            </tr>
                          ))}
                    </table>
                  </div>
                  <div>
                    <button onClick={() => listdisplaytoggle()}>Fermer</button>
                  </div>
                </div>
              </div>
            )}

            <div id="insidecontainer">
              <div id="leftpart">
                <div id="togglemap">
                  <button onClick={() => togglemapdisplay()}>
                    Carte interactive
                  </button>
                </div>

                {!isEmpty(actualzone) ? (
                  <div
                    className="projectlistcontainer"
                    style={
                      500 - blockY < 125 && 700 - blockX < 325
                        ? {
                            top: `${blockY - 125}px`,
                            left: `${blockX - 300}px`,
                          }
                        : 500 - blockY > 125 && 700 - blockX < 325
                        ? { top: `${blockY}px`, left: `${blockX - 300}px` }
                        : 500 - blockY > 125 && 700 - blockX > 325
                        ? { top: `${blockY}px`, left: `${blockX}px` }
                        : 500 - blockY < 125 && 700 - blockX > 325
                        ? { top: `${blockY - 125}px`, left: `${blockX}px` }
                        : null // Gérer le cas par défaut si nécessaire
                    }
                  >
                    <h2>Liste des projets</h2>
                    <ul>{actualzone && actualzone}</ul>
                  </div>
                ) : null}

                <div className="svgcontainer">
                  <svg
                    className={whichmap && devis ? "" : "hideme"}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={mapWidth}
                    height={mapHeight}
                    viewBox="0 0 310 310"
                    overflow="scroll"
                    style={{ marginTop: "3%" }}
                  >
                    {!isEmpty(zones) &&
                      zones.map((zone) => (
                        <a
                          key={zone.title}
                          onClick={() => deptinfo(zone.id, zone.title)}
                          // href={zone.url}
                        >
                          <path
                            id={zone.title}
                            title={zone.title}
                            d={zone.path}
                            stroke={mapstroke}
                            strokeWidth={"mapstroke_width"}
                            style={zonestyle(zone.id)} // Use the object directly
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            className="path"
                          />
                        </a>
                      ))}
                  </svg>
                  <svg
                    className={whichmap ? "hideme" : ""}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={mapWidth}
                    height={mapHeight}
                    viewBox="0 0 310 310"
                    overflow="scroll"
                    style={{ marginTop: "3%" }}
                    onClick={(e) => seticonpos(e)}
                  >
                    <defs>
                      <g id="Z75">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z62">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z59">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z65">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z54">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z76">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z94">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z95">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z77">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z13">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z51">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z2">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z85">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z21">
                        <path d={iconpath02} />
                      </g>
                      <g id="Z69">
                        <path d={iconpath02} />
                      </g>
                    </defs>
                    <g groupmode="layer">
                      {newmap.map((map) => (
                        <path
                          key={map.id}
                          // style={mapstyle}
                          d={map.path}
                          id={map.id}
                          stroke={mapstroke}
                          strokeWidth="0"
                          fill="#b7b5b6ad"
                        />
                      ))}
                      <use
                        href="#Z95"
                        x="45%"
                        y="13%"
                        onMouseEnter={(e) => zonework(e, "Z95")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z75"
                        // x="4970"
                        // y="2125"
                        // transform="scale(0.03)"
                        x="46%"
                        y="14.7%"
                        onMouseEnter={(e) => zonework(e, "Z75")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z62"
                        x="42%"
                        y="0"
                        onMouseEnter={(e) => zonework(e, "Z62")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z59"
                        x="52%"
                        y="0"
                        onMouseEnter={(e) => zonework(e, "Z59")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z65"
                        x="29%"
                        y="74%"
                        onMouseEnter={(e) => zonework(e, "Z65")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z54"
                        x="72%"
                        y="17%"
                        onMouseEnter={(e) => zonework(e, "Z54")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z94"
                        x="46.5%"
                        y="16%"
                        onMouseEnter={(e) => zonework(e, "Z94")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z77"
                        x="49%"
                        y="14%"
                        onMouseEnter={(e) => zonework(e, "Z77")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z76"
                        x="37%"
                        y="9%"
                        onMouseEnter={(e) => zonework(e, "Z76")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z13"
                        x="62%"
                        y="68%"
                        onMouseEnter={(e) => zonework(e, "Z13")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z51"
                        x="56.5%"
                        y="13%"
                        onMouseEnter={(e) => zonework(e, "Z51")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z2"
                        x="53%"
                        y="9%"
                        onMouseEnter={(e) => zonework(e, "Z2")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z85"
                        x="20%"
                        y="37%"
                        onMouseEnter={(e) => zonework(e, "Z85")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z21"
                        x="63%"
                        y="31.5%"
                        onMouseEnter={(e) => zonework(e, "Z21")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                      <use
                        href="#Z69"
                        x="61.5%"
                        y="45%"
                        onMouseEnter={(e) => zonework(e, "Z69")}
                        onMouseLeave={() => setActualzone(null)}
                        className="mapicon"
                      />
                    </g>
                  </svg>
                </div>
              </div>

              <div id="rightpart">
                <div id="rightbox" className={whichmap ? "" : "hideme"}>
                  <div className="projectbox">
                    <div className="morebuttons">
                      <button
                        onClick={() => devisbutton()}
                        style={{
                          background: devis ? "#000066" : "whitesmoke",
                          color: devis ? "whitesmoke" : "#000066",
                          // width: "100px"
                        }}
                      >
                        Devis
                      </button>
                      <button
                        onClick={() => missionbutton()}
                        style={{
                          background: !devis ? "#000066" : "whitesmoke",
                          color: !devis ? "whitesmoke" : "#000066",
                          // width: "100px"
                        }}
                      >
                        Autres Missions
                      </button>
                      <button>Liste clients & projets</button>
                      <button
                        onClick={() => listdisplaytoggle()}
                        style={{
                          background: showpricelist ? "#000066" : "whitesmoke",
                          color: showpricelist ? "whitesmoke" : "#000066",
                          width: "100px",
                        }}
                      >
                        BDD tarifs
                      </button>
                    </div>

                    {devis ? (
                      // ---------------------- Section Devis
                      <div className="projectdetails">
                        <div className="headbox">
                          <div>
                            <h1>{mytitle}</h1>
                          </div>
                          <div className="projectbutton">
                            <button
                              style={
                                dept === null
                                  ? {
                                      color: "#fff",
                                      backgroundColor: "#000066",
                                    }
                                  : {
                                      backgroundColor: "whitesmoke",
                                      color: "#000066",
                                    }
                              }
                              id="btn00"
                              onClick={() => reinitialize()}
                            >
                              Tous les départements
                            </button>
                            <button
                              onClick={() => setLastprojectmodalbis(true)}
                            >
                              Dernières MAJ de devis
                            </button>
                          </div>
                        </div>

                        <h2>Liste des devis</h2>

                        <button
                          className={
                            selectedstatus == "prospect" ? "active" : ""
                          }
                          onClick={() => setSelectedstatus("prospect")}
                          id="btn01"
                        >
                          Devis en cours
                        </button>
                        <button
                          className={selectedstatus == "lost" ? "active" : ""}
                          onClick={() => setSelectedstatus("lost")}
                          id="btn02"
                        >
                          Non remporté
                        </button>
                        <button
                          className={selectedstatus == "left" ? "active" : ""}
                          onClick={() => setSelectedstatus("left")}
                          id="btn03"
                        >
                          Non répondu
                        </button>
                        <button
                          className={
                            selectedstatus == "inprogress" ? "active" : ""
                          }
                          onClick={() => setSelectedstatus("inprogress")}
                          id="btn04"
                        >
                          En prod
                        </button>
                        <button
                          className={
                            selectedstatus == "standby" ? "active" : ""
                          }
                          onClick={() => setSelectedstatus("standby")}
                          id="btn05"
                        >
                          Stand by
                        </button>
                        <button
                          className={selectedstatus == "done" ? "active" : ""}
                          onClick={() => setSelectedstatus("done")}
                          id="btn06"
                        >
                          Terminé
                        </button>
                        <button className="addnew" onClick={() => showmodal()}>
                          Ajouter un nouveau projet
                        </button>
                        <div className="affichage">
                          <input
                            type="text"
                            id="mysearchbox"
                            placeholder="Recherche par mot-clé"
                            onChange={(e) => lookatme(e.target.value)}
                          />
                        </div>

                        <select
                          name="selectspecs"
                          id="selectspecs"
                          onChange={(e) => filtermethod(e.target.value)}
                        >
                          <option value="projet"> afficher par projet</option>
                          <option value="client">
                            afficher par nom client
                          </option>
                          <option value="datedemande">
                            afficher par date de demande
                          </option>
                          <option value="deadline">
                            afficher par deadline
                          </option>
                          <option value="datedebutprevisionnelle">
                            afficher par date de début
                          </option>
                          <option value="budgetestimatif">
                            par budget estimatif
                          </option>
                        </select>

                        <ul className="selectlist">
                          {!isEmpty(projects) &&
                            projects
                              .filter((project) =>
                                dept ? project.departement === dept : true
                              )
                              .filter((project) =>
                                mysearch
                                  ? removeAccents(
                                      project.nom.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    ) ||
                                    removeAccents(
                                      project.typeprestations.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    ) ||
                                    removeAccents(
                                      project.description.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    )
                                  : true
                              )
                              .filter(
                                (project) => project.statut === selectedstatus
                              )
                              .sort((a, b) => {
                                const orderBy = (key, descending = false) => {
                                  const order = descending ? -1 : 1;
                                  return (a, b) =>
                                    a[key] > b[key]
                                      ? order
                                      : a[key] < b[key]
                                      ? -order
                                      : 0;
                                };

                                const criteria = [
                                  "projet",
                                  "client",
                                  "datedemande",
                                  "deadline",
                                  "datedebutprevisionnelle",
                                  "budgetestimatif",
                                  "listeprix",
                                ];

                                for (const criterion of criteria) {
                                  const comparison = orderBy(criterion, true);
                                  const result = comparison(a, b);
                                  if (result !== 0) return result;
                                }

                                return 0;
                              })
                              .map((project) => (
                                <li key={project.id}>
                                  <a
                                    onClick={() => selectedproject(project.id)}
                                  >
                                    {myfilter === "projet" && (
                                      <>
                                        {project.nom}
                                        <span className="projectpriority">
                                          Priorité : {project.priorite}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "client" && (
                                      <>
                                        {project.client}
                                        <span className="projectstate">
                                          {project.statut}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "datedemande" && (
                                      <>
                                        {project.nom}
                                        <span className="projectstate">
                                          {project.datedemande}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "deadline" && (
                                      <>
                                        {project.nom}
                                        <span className="projectstate">
                                          {project.deadline}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "datedebutprevisionnelle" && (
                                      <>
                                        {project.nom}
                                        <span className="projectstate">
                                          {project.datedebutprevisionnelle}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "budgetestimatif" && (
                                      <>
                                        {project.nom}
                                        <span className="projectstate">
                                          {project.budgetestimatif}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "listeprix" && (
                                      <>
                                        {/* {project.nom} */}
                                        Liste de prix
                                        <span className="projectstate">
                                          prix
                                        </span>
                                      </>
                                    )}
                                  </a>
                                </li>
                              ))}
                        </ul>
                      </div>
                    ) : (
                      // ---------------------- Section Missions
                      <div className="missiondetails">
                        <h2>Missions</h2>

                        <button
                          className={
                            selectedstatus == "inprogress" ? "active" : ""
                          }
                          onClick={() => setSelectedstatus("inprogress")}
                          id="btn01"
                        >
                          En cours
                        </button>
                        <button
                          className={selectedstatus == "lost" ? "active" : ""}
                          onClick={() => setSelectedstatus("lost")}
                          id="btn02"
                        >
                          Annulé
                        </button>
                        <button
                          className={
                            selectedstatus == "standby" ? "active" : ""
                          }
                          onClick={() => setSelectedstatus("standby")}
                          id="btn03"
                        >
                          Stand By
                        </button>
                        <button
                          className={selectedstatus == "done" ? "active" : ""}
                          onClick={() => setSelectedstatus("done")}
                          id="btn04"
                        >
                          Terminé
                        </button>
                        <button
                          className="addnew"
                          onClick={() => setShowmodal02(true)}
                        >
                          Ajouter une nouvelle mission
                        </button>
                        <div className="affichage">
                          <input
                            type="text"
                            id="mysearchbox"
                            placeholder="Recherche par mot-clé"
                            onChange={(e) => lookatme(e.target.value)}
                          />
                        </div>

                        <select
                          name="selectspecs"
                          id="selectspecs"
                          onChange={(e) => filtermethod(e.target.value)}
                        >
                          <option value="projet"> afficher par mission</option>
                          <option value="datedemande">
                            afficher par date de demande
                          </option>
                          <option value="deadline">
                            afficher par deadline
                          </option>
                        </select>

                        <ul className="selectlist">
                          {!isEmpty(missions) &&
                            missions
                              .filter((mission) =>
                                mysearch
                                  ? removeAccents(
                                      mission.nom.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    ) ||
                                    removeAccents(
                                      mission.typeprestations.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    ) ||
                                    removeAccents(
                                      mission.description.toLowerCase()
                                    ).includes(
                                      removeAccents(mysearch.toLowerCase())
                                    )
                                  : true
                              )
                              .filter(
                                (mission) => mission.statut === selectedstatus
                              )
                              .sort((a, b) => {
                                const orderBy = (key, descending = false) => {
                                  const order = descending ? -1 : 1;
                                  return (a, b) =>
                                    a[key] > b[key]
                                      ? order
                                      : a[key] < b[key]
                                      ? -order
                                      : 0;
                                };

                                const criteria = [
                                  "projet",
                                  "client",
                                  "datedemande",
                                  "deadline",
                                  "datedebutprevisionnelle",
                                  "budgetestimatif",
                                  "listeprix",
                                ];

                                for (const criterion of criteria) {
                                  const comparison = orderBy(criterion, true);
                                  const result = comparison(a, b);
                                  if (result !== 0) return result;
                                }

                                return 0;
                              })
                              .map((mission) => (
                                <li key={mission.id}>
                                  <a
                                    onClick={() => selectedmission(mission.id)}
                                  >
                                    {myfilter === "projet" && (
                                      <>
                                        {mission.nom}
                                        <span className="projectpriority">
                                          Priorité : {mission.priorite}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "datedemande" && (
                                      <>
                                        {mission.nom}
                                        <span className="projectstate">
                                          {mission.datedemande}
                                        </span>
                                      </>
                                    )}
                                    {myfilter === "deadline" && (
                                      <>
                                        {mission.nom}
                                        <span className="projectstate">
                                          {mission.deadline}
                                        </span>
                                      </>
                                    )}
                                  </a>
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal />

        {/* // ---------------------- Section Ajout Mission */}
        <div className={showmodal02 ? "showmodal" : ""} id="mymodal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="entete">
                <h3>Nouvelle mission</h3>
              </div>
            </div>

            <div className="modal-body">
              <div className="bodycontainer">
                <div className="homeform">
                  <form
                    method="post"
                    action="https://api.axel.mg/addnewmission"
                    id="myform"
                  >
                    <div className="midtitle">A propos de la mission : </div>

                    <div className="inputtitle">Nom de la mission : </div>
                    <input
                      type="text"
                      name="nom"
                      id="nom"
                      className="longinput"
                    />
                    <br />

                    <div className="inputtitle">Objectif : </div>
                    <input
                      type="text"
                      name="typeprestations"
                      id="typeprestations"
                      className="longinput"
                    />
                    <br />

                    <div className="inputtitle">Date de la demande : </div>
                    <input
                      type="date"
                      name="datedemande"
                      id="datedemande"
                      className="longinput"
                    />

                    <div className="inputtitle">Deadline : </div>
                    <input
                      type="date"
                      name="deadline"
                      id="deadline"
                      className="longinput"
                    />

                    <div className="inputtitle">Description </div>
                    <textarea
                      name="description"
                      id="description"
                      className="longinput"
                      rows="6"
                    ></textarea>
                    <br />

                    <div className="inputtitle">Statut: </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          name="statut"
                          value="inprogress"
                          defaultChecked
                        />
                        En cours
                      </label>

                      <label>
                        <input type="radio" name="statut" value="lost" />
                        Annulé
                      </label>

                      <label>
                        <input type="radio" name="statut" value="standby" />
                        Stand By
                      </label>

                      <label>
                        <input type="radio" name="statut" value="done" />
                        Terminé
                      </label>
                    </div>

                    <br />

                    <div className="inputtitle">Responsable: </div>
                    <input
                      type="text"
                      name="responsable"
                      id="responsable"
                      className="longinput"
                    />
                    <br />

                    <br />

                    <button type="submit" id="validateform">
                      Envoyer
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button id="closeme" onClick={() => closefile()}>
                Fermer
              </button>
            </div>
          </div>
        </div>

        {/* // ---------------------- Section Ajout projet */}
        <Ficheprojet />

        {/* // ---------------------- Section Mission Modal */}
        <div
          className={fichemission && fichemission ? "showproject" : ""}
          id="modalcontainer"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="entete">
                <div className="bigtitle">
                  <h1>{!isEmpty(mission) && mission.nom}</h1>
                  <h2>{!isEmpty(mission) && mission.typeprestations}</h2>
                  <div className="mymissionpriority">
                    {/* // ---------------------- Set Statut de la mission : CHANGER LA METHODE */}
                    <form ref={stateform} onChange={() => setstatus()}>
                      <p>
                        Statut : &nbsp;
                        <input
                          type="hidden"
                          name="prioid"
                          value={!isEmpty(mission) && mission.id}
                        />
                        <select name="missionstate" id="missionstate">
                          <option>{!isEmpty(mission) && mission.statut}</option>
                          <option value="inprogress">En cours</option>
                          <option value="lost">Abandonné</option>
                          <option value="standby">Stand by</option>
                          <option value="done">Terminé</option>
                        </select>
                      </p>
                    </form>
                    {/* // ---------------------- Set Priority de la mission : CHANGER LA METHODE */}
                    <form ref={prioform} onChange={() => setpriority()}>
                      <p>
                        Priorité : &nbsp;
                        <input
                          type="hidden"
                          name="prioid"
                          value={!isEmpty(mission) && mission.id}
                        />
                        <select name="missionprio" id="missionprio">
                          <option>
                            {!isEmpty(mission) && mission.priorite}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-body">
              <div className="bodycontainer">
                <div className="projectcontent">
                  <div className="projectsidebar">
                    <div className="leftside">
                      <h3>A propos de la mission</h3>
                      <div className="part3">
                        <span>
                          <b>Mission </b> :{" "}
                        </span>
                        <span style={{ color: "green" }}>
                          {!isEmpty(mission) && mission.nom}
                        </span>
                      </div>
                      <div className="part4">
                        <span>
                          <b>Date demande</b> :{" "}
                        </span>
                        <span>{!isEmpty(mission) && mission.datedemande}</span>
                      </div>
                      <div className="part5">
                        <span>
                          <b>Deadline</b> :{" "}
                        </span>
                        <span>{!isEmpty(mission) && mission.deadline}</span>
                      </div>
                      <div className="part10">
                        <span>
                          <b>Statut</b> :{" "}
                        </span>
                        <span>{!isEmpty(mission) && mission.statut}</span>
                      </div>
                    </div>
                    <div className="leftpart">
                      <div className="container">
                        <h2>A faire</h2>
                        {/* // ---------------------- Form - TodoList : Modifié */}
                        <form
                          ref={form}
                          onSubmit={(e) => handleform(e)}
                          // onSubmit={(e) => e.preventDefault()}
                        >
                          <input
                            type="hidden"
                            name="project"
                            value="0"
                            id="project"
                          />
                          <input
                            type="hidden"
                            name="mission"
                            value={
                              !isEmpty(missions) &&
                              Number(!isEmpty(mission) && mission.id)
                            }
                            id="mission"
                          />

                          <input
                            type="text"
                            id="todo"
                            name="todo"
                            placeholder="Renseignez les tâches à faire, ici"
                          />
                        </form>
                        <ul id="list">
                          {!isEmpty(todos) &&
                            todos
                              .filter((todo) => {
                                return (
                                  !isEmpty(missions) &&
                                  Number(todo.mission) === Number(mission.id)
                                );
                              })
                              .map((todo) => (
                                <li key={todo.id}>
                                  <div className="leftli">
                                    <span
                                      style={
                                        Number(todo.checked) === 1
                                          ? { textDecoration: "line-through" }
                                          : {}
                                      }
                                    >
                                      {todo.todo}
                                    </span>
                                  </div>
                                  <div className="rightli">
                                    <button onClick={() => checkme(todo.id)}>
                                      Check{" "}
                                    </button>
                                    <button onClick={() => deleteme(todo.id)}>
                                      Delete
                                    </button>
                                  </div>
                                </li>
                              ))}
                        </ul>
                      </div>
                      <div className="container02">
                        <h2>Liens</h2>
                        <ul id="list">
                          {!isEmpty(links) &&
                            links
                              .filter((link) => {
                                return (
                                  !isEmpty(missions) &&
                                  Number(link.missionid) === Number(mission.id)
                                );
                              })
                              .map((link) => (
                                <li key={link.id}>
                                  <div className="leftli" key={link.id}>
                                    <a
                                      key={link.id}
                                      href={link.filelink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => {
                                        e.preventDefault(); // Empêche le comportement par défaut du lien
                                        window.open(link.filelink, "_blank");
                                      }}
                                    >
                                      {link.filename}
                                    </a>
                                  </div>
                                  <div className="rightli">
                                    <button onClick={() => deletelink(link.id)}>
                                      Delete
                                    </button>
                                  </div>
                                </li>
                              ))}
                        </ul>
                        {/* // ---------------------- Form - Links : Modifié */}
                        <form ref={mylink} onSubmit={(e) => linkform(e)}>
                          <h4>Ajouter un nouveau lien</h4>
                          <input
                            type="text"
                            name="filename"
                            id="filename"
                            placeholder="Entrer le nom du fichier"
                            // defaultValue="Mon fichier"
                          />
                          <input
                            id="filelink"
                            name="filelink"
                            rows="5"
                            placeholder="Coller le lien, ici"
                          />
                          <input
                            type="hidden"
                            name="projectid"
                            id="projectid"
                            value="0"
                          />
                          <input
                            type="hidden"
                            name="missionid"
                            id="missionid"
                            value={!isEmpty(missions) && Number(mission.id)}
                          />
                          <input type="submit" value="Ajouter" />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="projectmaincontent">
                    {/* <div className="rightside">
                      <h3>Description</h3>
                      <p>{!isEmpty(mission) && mission.description}</p>
                    </div> */}
                    <div className="container">
                      {/* <h2>Commentaires</h2>
                      <ul id="list">
                        {!isEmpty(comments) &&
                          comments
                            .filter((comment) => {
                              return (
                                !isEmpty(missions) &&
                                Number(comment.midforcomment) === Number(mission.id)
                              );
                            })
                            .map((comment) => (
                              <li key={comment.id}>
                                <div className="leftli">
                                  <span>{comment.datecomment}</span>
                                  <p>{comment.commentaire}</p>
                                </div>
                                <div className="rightli">
                                  <button onClick={() => deletecomment(comment.id)}>
                                    Delete
                                  </button>
                                </div>
                              </li>
                            ))}
                      </ul> */}

                      <h2>Notes</h2>
                      <ul id="list">
                        {!isEmpty(comments) &&
                          comments
                            .filter((comment) => {
                              return (
                                !isEmpty(missions) &&
                                Number(comment.midforcomment) ===
                                  Number(mission.id)
                              );
                            })
                            .map((comment) => (
                              <li key={comment.id}>
                                <div className="leftli">
                                  <span>{comment.datecomment}</span>
                                  {/* <p>{comment.commentaire}</p> */}
                                  {/* Utiliser convertDraftToHtml pour afficher le contenu Draft.js */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: convertDraftToHtml(
                                        JSON.parse(comment.commentaire)
                                      )
                                    }}
                                  />
                                </div>
                                <div className="rightli">
                                  <button
                                    onClick={() => deletecomment(comment.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </li>
                            ))}
                      </ul>

                      {/* // ---------------------- Form - Commentaires : Modifié */}
                      <form
                        ref={commentaire}
                        onSubmit={(e) => commentform(e)}
                        style={{ backgroundColor: "#fff", padding: "10px" }}
                      >
                        <input
                          type="hidden"
                          name="datecomment"
                          id="datecomment"
                          value={currentDateTime}
                        />
                        <input
                          type="hidden"
                          name="pidforcomment"
                          id="pidforcomment"
                          value="0"
                        />
                        <input
                          type="hidden"
                          name="midforcomment"
                          id="midforcomment"
                          value={!isEmpty(missions) && Number(mission.id)}
                        />
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={handleEditorChange}
                          // toolbar={{
                          //   options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                          // }}
                        />
                        <input type="submit" value="Commenter" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button id="closeme" onClick={() => setFichemission(false)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
